import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// BS modules
import { AuthenticationmanagerService } from '../../services/authenticationmanager.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthenticationmanagerService,
    private router: Router
  ) {} 

  canActivate(): Observable<boolean> {
    return this.auth.checkTokenValidity().pipe(
      tap(x => { if (!x) { this.auth.setUserNotAuthenticated(); this.router.navigate(['login']); } })
    );
  }
}
