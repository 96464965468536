import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { WsmanagerService } from 'src/app/services/wsmanager.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConvDataDefinition } from 'src/app/classes/conv/ConvDataDefinition';
import { WidgetsmanagerService } from 'src/app/services/widgetsmanager.service';

@Component({
  selector: 'conv-data-definition-detail',
  templateUrl: './conv-data-definition-detail.component.html',
  styleUrls: ['./conv-data-definition-detail.component.scss'],
})
export class ConvDataDefinitionDetailComponent implements OnInit {

  @Input('convDataDefinition')
  public convDataDefinition: ConvDataDefinition;

  @Input('closeEvent')
  public closeEvent: EventEmitter<any>;

  @Output('newDataDef')
  public newDataDef: EventEmitter<ConvDataDefinition>;

  @Output('dataDefsUpdated')
  private dataDefsUpdated: EventEmitter<ConvDataDefinition> = new EventEmitter<ConvDataDefinition>();

  // form
  public addDataDefForm: FormGroup;
  public loading = true;
  public validation_messages = null;
  public mexSuccess: string = null;
  public mexError: string = null;
  public types: string[];
  public isEditMode = true;

  constructor(
    private formBuilder: FormBuilder,
    private wsManager: WsmanagerService,
    private translate: TranslateService,
    private wdManagerService: WidgetsmanagerService
  ) { }

  ngOnInit() {

    if (!this.convDataDefinition) {
      this.convDataDefinition = new ConvDataDefinition();
      this.isEditMode = false;
    }

    // init form
    this.addDataDefForm = this.formBuilder.group({
      id: [this.convDataDefinition.id, [Validators.nullValidator]],
      title: [this.convDataDefinition.title, [Validators.required]],
      description: [this.convDataDefinition.description, [Validators.required]],
      type: [this.convDataDefinition.type, [Validators.required]],
    });
    if (this.isEditMode) {
      this.addDataDefForm.addControl('id', new FormControl(this.convDataDefinition.id));
    }

    // init mex validazione
    this.validation_messages = {
      title: [
        { type: 'required', message: 'Field is required' },
      ],
      description: [
        { type: 'required', message: 'Field is required' },
      ],
      type: [
        { type: 'required', message: 'Field is required' },
      ]
    };

    // init etichette
    this.mexSuccess = this.translate.instant(this.isEditMode ? 'Data definition modified' : 'Data definition added');
    this.mexError = this.translate.instant(this.isEditMode ? 'Error modifying data definition' : 'Error adding data definition');
    this.loading = false;
  }

  onSubmit(values) {
    this.loading = true;
    const obs = this.isEditMode ? this.wsManager.editConvDataDefinition(values.id, values) : this.wsManager.newConvDataDefinition(values);

    obs.subscribe(data => {
      this.convDataDefinition = data;
      this.loading = false;
      this.update();
      this.wdManagerService.showToastMessage('success', this.mexSuccess, 1000);
    }, err => {
      this.loading = false;
      this.wdManagerService.showToastMessage('danger', this.mexError, 1000);
    });
  }

  update() {
    this.loading = true;
    this.wsManager.getConvDataDefinition(this.convDataDefinition.id).subscribe(res => {
      this.convDataDefinition = res;
      this.addDataDefForm.patchValue({
        id: this.convDataDefinition.id,
        title: this.convDataDefinition.title,
        type: this.convDataDefinition.type,
        description: this.convDataDefinition.description
      });
      if (this.isEditMode) {
        this.dataDefsUpdated.emit(this.convDataDefinition);
      } else {
        this.newDataDef.emit(this.convDataDefinition);
        this.isEditMode = true;
      }
      this.loading = false;
    }, err => {
      this.wdManagerService.showToastMessage('danger', 'Error while retrieving user info');
      this.close();
    })
  }

  close() {
    if (this.closeEvent) {
      this.closeEvent.emit();
    }
  }

}
