import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { SettingsmanagerService } from 'src/app/services/settingsmanager.service';
import { TranslateService } from '@ngx-translate/core';
import { WsmanagerService } from 'src/app/services/wsmanager.service';
import { WidgetsmanagerService } from 'src/app/services/widgetsmanager.service';
import { ConvInvocation } from 'src/app/classes/conv/ConvInvocation';
import { ConvUserData } from 'src/app/classes/conv/ConvUserData';
import { Types } from 'src/app/classes/conv/ConvDataDefinition';

@Component({
  selector: 'conv-invocation-detail',
  templateUrl: './conv-invocation-detail.component.html',
  styleUrls: ['./conv-invocation-detail.component.scss'],
})
export class ConvInvocationDetailComponent implements OnInit {

  @Input('convInvocation')
  public convInvocation: ConvInvocation;

  @Input('closeEvent')
  public closeEvent: EventEmitter<any>;

  @Output('convInvocationUpdated')
  private convInvocationUpdated: EventEmitter<ConvInvocation> = new EventEmitter<ConvInvocation>();

  public filteredConvInvocation : LocalDataSource;
  public userDatas: ConvUserData[];
  public imageUrls = {};
  public loading = true;

  constructor(
    public settingsManager: SettingsmanagerService,
    private translate: TranslateService,
    private wsManager: WsmanagerService,
    private widgetsManager: WidgetsmanagerService
    
  ) { }

  ngOnInit() {
    this.updateConvInvocation();
  }

  updateConvInvocation(){
    this.loading = true;
    this.wsManager.getConvInvocation(this.convInvocation.id).subscribe( res => {
      this.convInvocation = res;
      this.convInvocationUpdated.emit(this.convInvocation);
      this.wsManager.getUserDatasByInvocationId(this.convInvocation.id).subscribe( data => {
        this.userDatas = data;
        this.populatePhotos();
        this.loading = false;
      }, err => {
        console.log(err);
        this.widgetsManager.showToastMessage('danger', 'Error while retrieving convention invocation info');
        this.close();
      })
    }, err => {
      console.log(err);
      this.widgetsManager.showToastMessage('danger', 'Error while retrieving convention invocation info');
      this.close();
    });
  }

  populatePhotos() {
    this.userDatas.forEach(convUserData => {
      if (convUserData.convDataDefinition.type === Types.Ph){
        this.wsManager.downloadConvUserDataPhoto(convUserData.id).subscribe(blob => {
          const url = URL.createObjectURL(blob);
          this.imageUrls[convUserData.id] = url;
        }, err => {
          console.log(err);
        });
      }
    });
  }
  
  toggleUserConvention(){
    this.loading = true;
    this.wsManager.toggleUserConvention(this.convInvocation.id, !this.convInvocation.enabled).subscribe( res => {
      this.convInvocation = res;
      this.convInvocationUpdated.emit(res);
      this.loading = false;
    }, err => {
      if (err.error.error)
        this.widgetsManager.showToastMessage('danger', this.translate.instant(err.error.error));
      this.loading = false;
    })
  }

  close(){
    if (this.closeEvent) {
      this.closeEvent.emit();
    }
  }

  verify(){
    this.loading = true;
    this.wsManager.verifyConvInvocation(this.convInvocation.id).subscribe( _ => {
      this.convInvocation.verified = true;
      this.widgetsManager.showToastMessage('success', this.translate.instant('Verified'));
      this.loading = false;
    }, err => {
      console.log(err);
      this.widgetsManager.showToastMessage('error', 'Error while verifying invocation');
      this.loading = false;
    });
  }

  enlarge(id: number){
    window.open(this.imageUrls[id]);
  }

  toggleBlock(){
    this.loading = true;
    const blocked = this.convInvocation.blocked;
    const id = this.convInvocation.id;
    this.wsManager.setInvocationBlock(id, ! blocked).subscribe(_ => {
      this.convInvocation.blocked = ! blocked;
      if (! blocked)
        this.convInvocation.enabled = false;
      this.loading = false;
    }, err => {
      console.log(err);
      this.loading = false;
    })
  }
}
