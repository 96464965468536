
import * as bcrypt from 'bcryptjs';
import * as moment from 'moment';
import * as config from 'src/environments/appconfig.json';

const prepareDate = (subtractMinute = false) => {
  let now = Number(moment().format('YYYYYMMDDHHmm'));
  if (subtractMinute) {
    now -= 1;
  }
  return `${now}`;
}

const prepareToHash = (now: string, ...args: string[]) => {
  const secret = config.appSecretKey;
  return `${args.join(':')}:${secret}${now}`;
}

export class AppKeyManager {

  static createAppKeyHash = (...args: string[]): string => {
    const now = prepareDate();
    const prepared = prepareToHash(now, ...args);
    return bcrypt.hashSync(prepared);
  }

  static checkAppKeyHash = (appKey: string, ...args: string[]): boolean => {
    // primo tentativo
    const now = prepareDate();
    const prepared = prepareToHash(now, ...args);
    const check = bcrypt.compareSync(prepared, appKey);
    if (!check) {
      // secondo tentativo sottraendo un minuto
      const now_1 = prepareDate(true);
      const prepared = prepareToHash(now_1, ...args);
      return bcrypt.compareSync(prepared, appKey);
    } else {
      return check;
    }
  }
}
