import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Voucher } from 'src/app/classes';

@Component({
  selector: 'app-voucher-detail',
  templateUrl: './voucher-detail.component.html',
  styleUrls: ['./voucher-detail.component.scss'],
})
export class VoucherDetailComponent implements OnInit {

  @Input('voucher')
  public voucher: Voucher;

  @Input('closeEvent')
  public closeEvent: EventEmitter<any>;

  @Output('deleteEvent')
  public deleteEvent: EventEmitter<Voucher> = new EventEmitter<Voucher>();

  constructor() { }

  ngOnInit() { }

  deleteVoucher() {
    this.deleteEvent.emit(this.voucher);
    this.close();
  }

  close() {
    if (this.closeEvent) {
      this.closeEvent.emit();
    }
  }

}
