import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SettingsmanagerService } from '../../services/settingsmanager.service';
import { AppKeyManager } from 'src/app/utils/app-key-manager';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private _settingsMngSvc: SettingsmanagerService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const tok = this._settingsMngSvc.getToken();
        if (tok) {
            const hash = AppKeyManager.createAppKeyHash(tok);
            request = request.clone({
                setHeaders: {
                    'auth': `${tok}:${hash}`
                }
            });
        }

        return next.handle(request);
    }
}
