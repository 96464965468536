import {AfterViewInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { Position, Rental } from '../../classes';
import { WsmanagerService } from '../../services/wsmanager.service';
import * as Leaflet from 'leaflet';
import { Platform } from '@ionic/angular';

const iconRetinaUrl = '../../assets/leaflet/marker-icon-2x.png';
const iconUrl = '../../assets/leaflet/marker-icon.png';
const shadowUrl = '../../assets/leaflet/marker-shadow.png';

const startColor = '#2AAD27';
const stopColor = '#CB2B3E';
const MAX_ZOOM = 18;

const markerStartStyle = `
  background-color: ${startColor};
  width: 3rem;
  height: 3rem;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF`;

const markerStopStyle = `
  background-color: ${stopColor};
  width: 3rem;
  height: 3rem;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF`;



@Component({
  selector: 'app-pathmap',
  templateUrl: './pathmap.component.html',
  styleUrls: ['./pathmap.component.scss']
})
export class PathmapComponent implements OnInit, AfterViewInit {

  @Input() rental: Rental;

  pathMap: Leaflet.Map;
  positions: Position[];
  startCoordinates = [];
  stopCoordinates = [];
  rentalCoordinates: Leaflet.LatLng[] = [];

  startIcon = Leaflet.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [0, 24],
    popupAnchor: [0, -36],
    html: `<span style="${markerStartStyle}" />`
  });

  stopIcon = Leaflet.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [0, 24],
    popupAnchor: [0, -36],
    html: `<span style="${markerStopStyle}" />`
  });

  options = {
    layers: [
      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {maxZoom: MAX_ZOOM, attribution: 'OpenStreetMap'})
    ],
    polyline: true,
    circle: true,
    polygon: true,
    marker: true,
    rectangle: true,
    tileSize: 512,
    zoomOffset: -1,
    zoom: 5,
    center: Leaflet.latLng(41.828429809246686, 12.443954957738278)
  };

  constructor(
      private wsManager: WsmanagerService,
      public plt: Platform
  ) {
  }

  ngAfterViewInit(): void {
    console.log('@@@ ngAfterViewInit @@@');
    this.fillMap();
  }

  ngOnInit(): void {
    console.log('@@@ Rental (ngOnInit):', this.rental);
  }

  onMapReady(map: Leaflet.Map): void {
    console.log('@@@ onMapReady @@@');
    this.pathMap = map;
    // Do stuff with map:
    // - center the map
    this.centerMap();
    // - draw the path of the rental
    this.drawRentalPath();
  }

  fillMap(): void {
    this.wsManager.getRentalPoisitions(this.rental.id)
        .subscribe((positions) => {
          if (positions.length) {
            this.positions = positions;
            this.startCoordinates.push([positions[0].latitude, positions[0].longitude]);
            this.stopCoordinates.push([positions[positions.length - 1].latitude, positions[positions.length - 1].longitude]);
            this.rentalCoordinates = this.positions.map((pos) => new Leaflet.LatLng (pos.latitude, pos.longitude));
            console.log('@@@ rentalCoordinates', this.rentalCoordinates);
          } else {
            console.log('ERR: no positions found for the rental:', this.rental.id);
          }
        }, (err) => {
          {
            console.log('ERR:', err);
          }
        });
  }

  centerMap():void {
    console.log('@@@ center Map @@');
    this.pathMap.flyTo(this.startCoordinates[0], 13);
  }

  drawRentalPath():void {

    // Disegna marker di inizio e fine noleggio
    Leaflet.marker(this.startCoordinates[0], {icon: this.startIcon}).addTo(this.pathMap);
    Leaflet.marker(this.stopCoordinates[0], {icon: this.stopIcon}).addTo(this.pathMap);

    // Mostra i punti del percorso
    this.showAllPositions();

    // Mostra la spezzata del percorso
    // const pol = Leaflet.polyline(this.rentalCoordinates);
    // this.pathMap.addLayer(pol);
    const pol = Leaflet.polyline(this.rentalCoordinates, {color: '#3880ff'}).addTo(this.pathMap);
    console.log('@@@@ DRAW1:', this.rentalCoordinates);

    // Adatta mappa a percorso
    this.pathMap.fitBounds(pol.getBounds(), {maxZoom: MAX_ZOOM});
  }

  showAllPositions(): void{

    // @ATT: Because of this bug:
    // https://stackoverflow.com/questions/41144319/leaflet-marker-not-found-production-env
    // We have to copy the leaflet icons into the assets directory.

    Leaflet.Marker.prototype.options.icon = Leaflet.icon({
      iconRetinaUrl,
      iconUrl,
      shadowUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowSize: [41, 41]
    });

    // Mostra tutti i punti tranne la partenza e l'arrivo
    for (let i=1; i < this.rentalCoordinates.length -1; i++) {
      Leaflet.marker(this.rentalCoordinates[i]).addTo(this.pathMap);
    }
    // for (const p of this.rentalCoordinates) {
    //   Leaflet.marker(p).addTo(this.pathMap);
    // }
  }


  ngOnDestroy(): void {
    // this.pathMap.remove();
  }

}
