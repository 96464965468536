import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VoucherDetailComponent } from './voucher-detail/voucher-detail.component';
import { HeaderComponent } from './header/header.component';
import { DeviceDetailComponent } from './device-detail/device-detail.component';
import { ViewAreaComponent } from './view-area/view-area.component';
import { HubDetailComponent } from './hub-detail/hub-detail.component';
import { HubEditorComponent } from './hub-editor/hub-editor.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { ConvDetailComponent } from './conv-detail/conv-detail.component';
import { ConvDataDefinitionDetailComponent } from './conv-data-definition-detail/conv-data-definition-detail.component';
import { ConvInvocationDetailComponent } from './conv-invocation-detail/conv-invocation-detail.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { AddSubscriptionComponent } from './add-subscription/add-subscription.component';
import { EditDeviceInfoComponent } from './edit-device-info/edit-device-info.component';
import { AddDeviceComponent } from './add-device/add-device.component';
import { FaultCommentsComponent } from './fault-comments/fault-comments.component';
import { RentalActiveDetailComponent } from './rental-active-detail/rental-active-detail.component';
import { TransactionDetailComponent } from './transaction-detail/transaction-detail.component';
import { ChargeReversalFormComponent } from './charge-reversal-form/charge-reversal-form.component';
import { TimeSlotsComponent } from './time-slots/time-slots.component';
import { PathmapComponent } from './pathmap/pathmap.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ModelDetailComponent } from '../managers/manage-models/model-detail/model-detail.component';

/**
 * Modulo che esporta tutti i componenti riutilizzabili nell'app,
 * Per utilizzare i componenti bisogna inserire questo modulo negli imports
 */
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    LeafletModule,
  ],
  declarations: [
    VoucherDetailComponent,
    HeaderComponent,
    DeviceDetailComponent,
    ViewAreaComponent,
    HubDetailComponent,
    HubEditorComponent,
    ConvDetailComponent,
    ConvDataDefinitionDetailComponent,
    ConvInvocationDetailComponent,
    SubscriptionsComponent,
    AddSubscriptionComponent,
    EditDeviceInfoComponent,
    ModelDetailComponent,
    AddDeviceComponent,
    FaultCommentsComponent,
    GoogleMapComponent,
    RentalActiveDetailComponent,
    TransactionDetailComponent,
    TimeSlotsComponent,
    ChargeReversalFormComponent,
    PathmapComponent
  ],
  exports: [
    VoucherDetailComponent,
    HeaderComponent,
    DeviceDetailComponent,
    ViewAreaComponent,
    HubDetailComponent,
    HubEditorComponent,
    ConvDetailComponent,
    ConvDataDefinitionDetailComponent,
    ConvInvocationDetailComponent,
    SubscriptionsComponent,
    AddSubscriptionComponent,
    EditDeviceInfoComponent,
    ModelDetailComponent,
    AddDeviceComponent,
    FaultCommentsComponent,
    GoogleMapComponent,
    RentalActiveDetailComponent,
    TransactionDetailComponent,
    TimeSlotsComponent,
    ChargeReversalFormComponent,
    PathmapComponent
  ],
  entryComponents: [
    VoucherDetailComponent,
    HeaderComponent,
    DeviceDetailComponent,
    ViewAreaComponent,
    HubDetailComponent,
    HubEditorComponent,
    ConvDetailComponent,
    ConvDataDefinitionDetailComponent,
    ConvInvocationDetailComponent,
    SubscriptionsComponent,
    AddSubscriptionComponent,
    EditDeviceInfoComponent,
    ModelDetailComponent,
    AddDeviceComponent,
    FaultCommentsComponent,
    GoogleMapComponent,
    RentalActiveDetailComponent,
    TransactionDetailComponent,
    TimeSlotsComponent,
    ChargeReversalFormComponent,
    PathmapComponent
  ]
})
export class ComponentsModule { }
