import { ConvCode } from './Conv';

export enum Types {
    Str = "String",
    Ph = "Photo"
}

export class ConvDataDefinition {
    public id: number;
    public title: string;
    public description: string;
    public type: Types;
    public convCodes: ConvCode[];
    private types: string[];

    constructor(obj?: any) {
        this.types = Object.keys(Types);
        if (!obj) {
            return;
        }
        Object.assign(this, obj);
    }
}