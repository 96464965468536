import { Component, Input, OnInit } from '@angular/core';
import { Model } from '../../../classes';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WsmanagerService } from '../../../services/wsmanager.service';
import { TranslateService } from '@ngx-translate/core';
import { WidgetsmanagerService } from '../../../services/widgetsmanager.service';
import {AlertController, PopoverController} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import { ModelService } from 'src/app/services/model.service';

@Component({
  selector: 'app-model-detail',
  templateUrl: './model-detail.component.html',
  styleUrls: ['./model-detail.component.scss'],
})
export class ModelDetailComponent implements OnInit {

  @Input('device')
  public model: Model;

  public isSpinnerVisible  = false;
  public modelForm: FormGroup;
  public validation_messages: any;
  public sourceFilename = '';
  private sourceBlobFile;
  public sourceImageUrl;

  constructor(
      private formBuilder: FormBuilder,
      private wsManager: WsmanagerService,
      private translate: TranslateService,
      private _sanitizer: DomSanitizer,
      public alertController: AlertController,
      private _modelSvc: ModelService,
      private widgetsManager: WidgetsmanagerService,
      private popoverController: PopoverController
  ) { }

  ngOnInit(): void {
    if (!this.model) {
      // Creazione di un nuovo modello
      this.model = new Model();
    }

    // gestione foto del modello
    if (this.model.photoFile){
      this.showImage(this.model.id);
    }

    // init form
    this.modelForm = this.formBuilder.group({
      id: [this.model.id],
      name: [this.model.name, [ Validators.required]],
      description: [this.model.description, [ Validators.required ]],
      active_fee: [this.model.active_fee, [ Validators.required ]],
      break_fee: [this.model.break_fee, [ Validators.required ]],
      unlock_fee: [this.model.unlock_fee, [ Validators.required ]],
      booking_fee: [this.model.booking_fee, [ Validators.required ]],
      time_threshold_min: [this.model.time_threshold_min, [ Validators.required ]],
      active_fee2: [this.model.active_fee2, [ Validators.required ]],
      break_fee2: [this.model.break_fee2, [ Validators.required ]],
      daily_fee: [this.model.daily_fee, [ Validators.required ]],
      km_fee: [this.model.km_fee, [ Validators.required ]],
      km_fee2: [this.model.km_fee2, [ Validators.required ]],
      limit_km_fee: [this.model.limit_km_fee, [ Validators.required ]],
      seats: [(this.model.seats ? this.model.seats : 1) , [ Validators.required ]],
      modelImage: [this.sourceFilename]
    });

    this.validation_messages = {
      name: [
        { type: 'required', message: 'This field is required.' },
      ],
      description: [
        { type: 'required', message: 'This field is required.' },
      ],
      active_fee: [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      break_fee: [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      unlock_fee: [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      booking_fee: [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      time_threshold_min: [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      active_fee2: [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      break_fee2: [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      daily_fee: [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      km_fee: [
        {type: 'required', message: 'This field is required.'}
      ],
      km_fee2: [
        {type: 'required', message: 'This field is required.'}
      ],
      limit_km_fee: [
        {type: 'required', message: 'This field is required.'}
      ],
      seats: [
        {type: 'required', message: 'This field is required.'}
      ],
      modelImage: [
        { type: 'required', message: 'This field is required.' }
      ],
    };
  }

  public async closePopover(reloadParent = false): Promise<void> {
    await this.popoverController.dismiss(reloadParent);
  }

  async onSubmit(): Promise<void> {
    this.isSpinnerVisible = true;
    const isNew = !this.modelForm.value.id;
    this._modelSvc.save(this.modelForm.value, this.sourceBlobFile).subscribe(
      () => {
        console.log('@@@ onSubmit EDIT OK @@@');
        this.isSpinnerVisible = false;
        const actiondMsg = this.translate.instant(isNew ? 'Model added successfully.' : 'Model edited successfully.');
        this.widgetsManager.showToastMessage('success', actiondMsg);
        this.closePopover(true);
      },
      () => {
        this.isSpinnerVisible = false;
        const actiondMsg = this.translate.instant(isNew ? 'Error creating the model.' : 'Error editing the model.');
        this.widgetsManager.showToastMessage('danger', actiondMsg);
      }
    );
  }

  chooseFile(event): void {
    this.sourceBlobFile = event.target.files[0];
    this.sourceFilename = this.sourceBlobFile.name;
    this.sourceImageUrl = this._sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(this.sourceBlobFile));
  }

  showImage(modelId): void {
    this._modelSvc.downloadImage(modelId).subscribe((data) => {
        this.sourceBlobFile = data;
        this.sourceFilename = data.name;
        this.sourceImageUrl = this._sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(data));
      }
    );
  }

  async deleteModel(): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant('Are you sure to proceed?'),
      buttons: [
        {
          text: this.translate.instant('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Ok',
          handler: () => {
            console.log('Delete zone');
            this._modelSvc.delete(this.model.id).subscribe(() => {
              const msgOK = this.translate.instant('Model removed successfully.');
              this.widgetsManager.showToastMessage('success', msgOK);
              this.closePopover(true);
            }, (err) => {
              console.log('ERR: ', err.message)
              const msgKO = this.translate.instant('Error removing the model.');
              this.widgetsManager.showToastMessage('danger', msgKO);
            });
          }
        }
      ]
    });

    await alert.present();
  }
}
