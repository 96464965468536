import { PricingPlan } from '../WSClasses';
import { ConvDataDefinition } from './ConvDataDefinition';

export class ConvCode {
    public id: number;
    public code: string;
    public enabled: boolean;
    public title: string;
    public description: string;
    public pricingPlan: PricingPlan;
    public convDataDefinitions: ConvDataDefinition[];

    constructor(obj?: any) {
        if (!obj) {
            return;
        }
        Object.assign(this, obj);
    }
}