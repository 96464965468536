import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Device } from '../classes';
import {SettingsmanagerService} from './settingsmanager.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private _url: string;

  constructor(
    private _http: HttpClient,
    private settingsManager: SettingsmanagerService
  ) {
    this._url =  this.settingsManager.getBaseWsUrl();
  }

  getDeviceDetail(id: number): Observable<Device> {
    return this._http.get<Device>(`${this._url}/device/${id}`).pipe(
        map(obj => new Device(obj))
    );
  }

  getDeviceExtendedData(id: number): Observable<any> {
    return this._http.get<any>(`${this._url}/device/${id}/extended`);
  }

  create(objDevice: Device): Observable<Device> {
    return this._http.post<Device>(`${this._url}/device/`, objDevice).pipe(
      map(obj => new Device(obj))
    );
  }

  edit(id: number, objDevice: Device): Observable<any> {
    return this._http.patch<any>(`${this._url}/device/${id}`, objDevice);
  }
}
