import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../classes/guards/auth.guard';

const routes: Routes = [
  { path: 'login', loadChildren: './public/login/login.module#LoginPageModule'},
  { path: 'pwdrecovery', loadChildren: './public/pwdrecovery/pwdrecovery.module#PwdrecoveryPageModule' },
  { path: 'pwdchange', loadChildren: './public/pwdchange/pwdchange.module#PwdchangePageModule' },
  {
    path: '',
    loadChildren: './menu/menu.module#MenuPageModule',
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  // imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class PagesRoutingModule { }

