import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WsmanagerService } from 'src/app/services/wsmanager.service';
import { ConvCode } from 'src/app/classes/conv/Conv';
import { ConvDataDefinition } from 'src/app/classes/conv/ConvDataDefinition';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PopoverController, ToastController } from '@ionic/angular';
import { PricingPlan } from 'src/app/classes';
import { WidgetsmanagerService } from 'src/app/services/widgetsmanager.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-conv-detail',
  templateUrl: './conv-detail.component.html',
  styleUrls: ['./conv-detail.component.scss'],
})
export class ConvDetailComponent implements OnInit {

  @Input('conv')
  public conv : ConvCode;

  @Input('closeEvent')
  public closeEvent: EventEmitter<any>;

  @Output('convUpdated')
  private convUpdated: EventEmitter<ConvCode> = new EventEmitter<ConvCode>();

  public displayName = '';
  public allDataDef: ConvDataDefinition[];
  public isEditMode = true;
  public convForm: FormGroup;
  public validation_messages: any;
  public loading = false;
  public pricingPlans: PricingPlan[];
  public mexSuccess;
  public mexError;

  constructor(
    private formBuilder: FormBuilder,
    private wsManager: WsmanagerService,
    private translate: TranslateService,
    private widgetsManager: WidgetsmanagerService
  ) { 

  }

  ngOnInit() {
    // init form
    this.wsManager.fetchConvDataDefinitions().subscribe( res => {
      this.allDataDef = res;
    }, err => {
      this.widgetsManager.showToastMessage('danger', 'Error while retrieving conv data definitions info');
    });

    this.wsManager.getPricingPlans().subscribe( res => {
      this.pricingPlans = res;
    }, err => {
      this.widgetsManager.showToastMessage('danger', 'Error while retrieving pricing plans info');
    });

    if (!this.conv){
      this.conv = new ConvCode();
      this.isEditMode = false;
    }
    
    this.convForm = this.formBuilder.group({
      id: [this.conv.id, [Validators.nullValidator]],
      code: [this.conv.code, [Validators.required]],
      title: [this.conv.title, [Validators.required]],
      description: [this.conv.description, [Validators.required]],
      enabled: [this.conv.enabled, [Validators.required]],
      convDataDefinitions: [this.conv.convDataDefinitions, [Validators.nullValidator]],
      pricingPlan: [this.conv.pricingPlan, [Validators.required]],
    });
    if (this.isEditMode) {
      this.convForm.addControl('id', new FormControl(this.conv.id));
    }

    // init mex validazione
    this.validation_messages = {
      code: [
      { type: 'required', message: 'Field is required.'},
      ],
      enabled: [
      { type: 'required', message: 'Field is required.'},
      ],
      title: [
        { type: 'required', message: 'Field is required.'},
      ],
      description: [
        { type: 'required', message: 'Field is required.'},
      ],
      pricingPlan: [
        { type: 'required', message: 'Field is required.'},
      ]
    };

    this.mexSuccess = this.translate.instant(this.isEditMode ? 'Convention modified' : 'Convention added');
    this.mexError = this.translate.instant(this.isEditMode ? 'Error modifying convention' : 'Error adding convention');
  }

  update() {
    this.loading = true;

    forkJoin(
      this.wsManager.fetchConvDataDefinitions(),
      this.wsManager.getPricingPlans(),
      this.wsManager.getConvCode(this.conv.code)
    ).subscribe((res) =>{
      this.allDataDef = res[0];
      this.pricingPlans = res[1];
      this.conv = res[2];
      this.convForm.patchValue({
        id: this.conv.id,
        code: this.conv.code,
        title: this.conv.title,
        description: this.conv.description,
        enabled: this.conv.enabled,
        convDataDefinitions: this.conv.convDataDefinitions,
        pricingPlan: this.conv.pricingPlan
      });
      this.convUpdated.emit(this.conv);
      this.loading = false;
    }, (err) => {
      this.widgetsManager.showToastMessage('danger', 'Error');
      this.loading = false;
      this.closeEvent.emit();
    });

  }

  compareWithDataDefs(o1: ConvDataDefinition, o2: ConvDataDefinition) {
    return o1.id === o2.id;
  };

  compareWithPricingPlan(o1: PricingPlan, o2: PricingPlan) {
    return o1.id === o2.id;
  }

  close(){
    if (this.closeEvent) {
      this.closeEvent.emit();
    }
  }

  onSubmit(values: ConvCode) {
    
    this.loading = true;
    // If no data definitions are provided, then they are an empty list
    if (! values.convDataDefinitions)
      values.convDataDefinitions = [];
      
    const obs = this.isEditMode ? this.wsManager.editConv(values) : this.wsManager.newConv(values);

    obs.subscribe(data => {
        this.conv = data;
        this.isEditMode = true;
        this.update();
        this.widgetsManager.showToastMessage('success', this.mexSuccess);
    }, err => {
        this.loading = false;
        this.widgetsManager.showToastMessage('danger', this.mexError);
    });
  }

}
