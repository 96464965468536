import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Fault } from 'src/app/classes';
import { AuthenticationmanagerService } from 'src/app/services/authenticationmanager.service';
import { WidgetsmanagerService } from 'src/app/services/widgetsmanager.service';
import { WsmanagerService } from 'src/app/services/wsmanager.service';

@Component({
  selector: 'app-fault-comments',
  templateUrl: './fault-comments.component.html',
  styleUrls: ['./fault-comments.component.scss'],
})
export class FaultCommentsComponent implements OnInit {

  @Input('fault')
  public fault: Fault;

  @Input('closeEvent')
  public closeEvent: EventEmitter<any>;

  @Output('closedFault')
  public closedFault: EventEmitter<Fault>;

  public addCommentForm: FormGroup;
  public validation_messages;
  public commentsList: any;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private widgetsManager: WidgetsmanagerService,
    private wsManager: WsmanagerService,
    private authService: AuthenticationmanagerService
  ) { }

  ngOnInit() {
    this.addCommentForm = this.formBuilder.group({
      comment: ['', Validators.compose([
        Validators.required
      ])]
    });
    
    this.validation_messages = {
      'comment': [
        { type: 'required', message: 'Field is required.' }
      ]
    };
    
    this.commentsList = JSON.parse(this.fault.comments)['comments'];
  }

  onSubmit(values: {comment: string}) {
    // Check that comment is not empty. If 'Enter' is pressed the validator is not checked
    if (values.comment == null || values.comment.length === 0)
      return;
    
    this.wsManager.addNewCommentToFault(
        this.fault.id,
        values.comment
    ).subscribe(data => {
      this.widgetsManager.showToastMessage('success', this.translate.instant('Comment added succesfully'));
      // this.close();
      this.commentsList.push({
        text: values.comment,
        datetime: new Date(),
        user: this.authService.getLoggedUserEmail()
      })
      //TODO
      //invece di chiudere popover aggiornare la visualizzazione 
      this.addCommentForm.reset();
    }, err => {
      this.widgetsManager.showToastMessage('danger', this.translate.instant('Error while adding comment'));
    });
  }

  async closeFault() {
    const header = this.translate.instant('Close fault');
    const inputs = [
      {
        name: 'price',
        id: 'price',
        type: 'number',
        placeholder: this.translate.instant('Final maintenance price'),
      }
    ];
    const buttons = [
      {
        text:  this.translate.instant('Cancel'),
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      }, {
        text:  this.translate.instant('Add'),
        cssClass: 'primary',
        handler: (data) => {
          // Creazione del guasto
          if ((data.deviceId === '') || (data.description === '')) {
            const message = this.translate.instant('All the fields are required.');
            this.widgetsManager.showToastMessage('danger', message, 2000);
            return;
          } else {
            this.wsManager.closeFault(this.fault.id, data.price)
                .subscribe(res => {
                  const message = this.translate.instant('Fault closed');
                  this.widgetsManager.showToastMessage('success', message, 2000);
                  this.closedFault.emit(this.fault);
                  this.close(); 
                  //TODO 
                  // invece di chiudere popover aggiornare la visualizzazione 
                }, (err) => {
                  console.log(err);
                  const message = this.translate.instant('Error closing the fault');
                  this.widgetsManager.showToastMessage('danger', message, 2000);
                });
          }
        }
      }
    ];

    this.widgetsManager.showAlert(header, "", buttons, inputs);
  }

  close(){
    if (this.closeEvent) {
      this.closeEvent.emit();
    }
  }
}
