import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { WidgetsmanagerService } from 'src/app/services/widgetsmanager.service';

@Component({
  selector: 'app-charge-reversal-form',
  templateUrl: './charge-reversal-form.component.html',
  styleUrls: ['./charge-reversal-form.component.scss'],
})
export class ChargeReversalFormComponent implements OnInit {

  @Input()
  public type: 'Charge' | 'Reversal';

  @Input()
  public id: number;

  @Input()
  public paymentId: string;

  @Input()
  public amount: number;

  @Input()
  public closeEvent: EventEmitter<any>;

  public reason: string;
  public form: FormGroup;
  public validationMessages: any;

  @Output()
  public data: EventEmitter<{
    id: number,
    amount: number,
    reason: string
  }>;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private widgetsManager: WidgetsmanagerService
  ) { }

  ngOnInit() {
    this.reason = this.type === "Charge" ? `Addebito pagamento: ` : `Storno pagamento: `;
    if (this.paymentId)
      this.reason += this.paymentId;

    this.form = this.formBuilder.group({
      id: [this.id, [Validators.required]],
      amount: [this.amount, [Validators.min(0), Validators.required]],
      reason: [this.reason, [Validators.required]]
    });
    this.validationMessages = {
      id: [
        { type: 'required', message: 'Field is required.' },
      ],
      amount: [
        { type: 'required', message: 'Field is required.' },
        { type: 'min', message: 'Value must be at least 0.' }
      ],
      reason: [
        { type: 'required', message: 'Field is required.' },
      ]
    }
  }

  close() {
    if (this.closeEvent) {
      this.closeEvent.emit();
    }
  }

  onSubmit(values: { id: number, amount: number, reason: string }) {
    const header = this.translate.instant("Confirm");
    const message = this.translate.instant(this.type === "Charge" ? "Are you sure to execute the charge?" : "Are you sure to execute the reversal?");
    const buttons = [{
      text: this.translate.instant('Cancel'),
      role: 'cancel',
    }, {
      text: this.translate.instant("Confirm"),
      handler: () => { this.data.emit(values); this.close(); }
    }]
    this.widgetsManager.showAlert(header, message, buttons);
  }

}
