import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Zone } from '../classes';
import {SettingsmanagerService} from './settingsmanager.service';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  private _url: string;

  constructor(
    private _http: HttpClient,
    private settingsManager: SettingsmanagerService
  ) {
    this._url =  this.settingsManager.getBaseWsUrl();
  }

  getByCity(idCity: number): Observable<Zone[]> {
    return this._http.get<Zone[]>(`${this._url}/zone/byCity/${idCity}`).pipe(
        map(obj => obj.map(xx => new Zone(xx)))
    );
  }

  create(objZone: Zone): Observable<Zone> {
    return this._http.post<Zone>(`${this._url}/zone/`, objZone).pipe(
      map(obj => new Zone(obj))
    );
  }

  edit(id: number, objZone: Zone): Observable<any> {
    return this._http.patch<any>(`${this._url}/zone/${id}`, objZone);
  }

  delete(id: number): Observable<any> {
    return this._http.delete<any>(`${this._url}/zone/${id}`);
  }
}
