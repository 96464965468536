import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { City, Device, DeviceType, Model } from 'src/app/classes';
import { DeviceService } from 'src/app/services/device.service';
import { ModelService } from 'src/app/services/model.service';
import { SettingsmanagerService } from 'src/app/services/settingsmanager.service';
import { WidgetsmanagerService } from 'src/app/services/widgetsmanager.service';

@Component({
  selector: 'app-edit-device-info',
  templateUrl: './edit-device-info.component.html',
  styleUrls: ['./edit-device-info.component.scss'],
})
export class EditDeviceInfoComponent implements OnInit {

  @Input('device')
  public device: Device;

  @Input('closeEvent')
  public closeEvent: EventEmitter<any>;

  @Output('deviceUpdated')
  public deviceUpdated: EventEmitter<Device>;

  public cities: City[] = this.settingsManager.getCitiesOptions();
  public states = {};
  public statesKeys = Object.keys(this.settingsManager.getDeviceStates());
  public deviceForm: FormGroup;
  public validation_messages: any;
  public loading = false;
  public deviceTypes = [];
  public models: Model[];

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private settingsManager: SettingsmanagerService,
    private widgetsManager: WidgetsmanagerService,
    private _deviceSvc: DeviceService,
    private _modelSvc: ModelService
  ) { }

  ngOnInit() {

    // Popola select del campo stato
    const states = this.settingsManager.getDeviceStates();
    for (const state of Object.keys(states)) {
      this.states[state] = states[state];
    }

    // Popola select del tipo di dispositivo
    for (const k of Object.keys(DeviceType)) {
      this.deviceTypes.push({'key': k, 'value': DeviceType[k]})
    }
    
    // Popola select dei modelli
    this._modelSvc.list().subscribe(
      (models) => { this.models = models; },
      (err) => { console.log('ERR (add-device):', err.message); }
    );

    this.deviceForm = this.formBuilder.group({
      id: [this.device.id, [
        Validators.required,
        Validators.pattern('^[0-9]*$')
      ]],
      name: [this.device.name, [
        Validators.required
      ]],
      imei: [this.device.imei, [
        Validators.required,
        Validators.minLength(15),
        Validators.maxLength(15),
        Validators.pattern('^[0-9]*$')
      ]],
      stateId: [this.device.stateId, [
        Validators.nullValidator
      ]],
      deviceType: [this.device.deviceType, [
        Validators.required
      ]],
      protocol: [this.device.protocol, [
        Validators.required
      ]],
      cityName: [this.settingsManager.getCityById(this.device.cityId), [
        Validators.required
      ]],
      modelId: [this.device.modelId, [
        Validators.required
      ]],
      iccid: [this.device.ICCID, [
        Validators.nullValidator
      ]],
      hw_version: [this.device.hw_version, [
        Validators.nullValidator
      ]],
      fw_version: [this.device.fw_version, [
        Validators.nullValidator
      ]],
      battery: [this.device.battery_fuel, [
        Validators.nullValidator
      ]],
      alarms_enabled: [this.device.alarms_enabled, [
        Validators.nullValidator
      ]]
    });

    this.validation_messages = {
      id: [
        { type: 'required', message: 'Field is required' },
        { type: 'pattern', message: 'Only numbers are accepted' }
      ],
      name: [
        { type: 'required', message: 'Field is required' },
      ],
      stateId: [
        { type: 'required', message: 'Field is required' },
      ],
      modelId: [
        { type: 'required', message: 'Field is required' },
      ],
      deviceType: [
        { type: 'required', message: 'Field is required' },
      ],
      imei: [
        { type: 'required', message: 'Field is required.' },
        { type: 'minlength', message: 'Imei length must be equal to 15.' },
        { type: 'maxlength', message: 'Imei length must be equal to 15.' },
        { type: 'pattern', message: 'Only numbers are accepted.' },
      ],
      protocol: [
        { type: 'required', message: 'Field is required' },
      ],
      cityName: [
        { type: 'required', message: 'Field is required' },
      ]
    };
  }

  update() {
    this.loading = true;
    this._deviceSvc.getDeviceDetail(this.device.id).subscribe((res: Device) => {
      this.device = res;

      this.deviceForm.patchValue({
        id: this.device.id,
        name: this.device.name,
        deviceType: this.device.deviceType,
        imei: this.device.imei,
        state: this.device.stateId,
        protocol: this.device.protocol,
        cityName: this.settingsManager.getCityById(this.device.cityId),
        modelId: this.device.modelId,
        iccid: this.device.ICCID,
        hw_version: this.device.hw_version,
        fw_version: this.device.fw_version,
        battery: this.device.battery_fuel,
        blocked: this.device.blocked,
        alarms_enabled: this.device.alarms_enabled ? 1: 0,
      });

      this.deviceUpdated.emit(this.device);
      this.loading = false;
    }, err => {
      this.loading = false;
      console.log(err);
      this.widgetsManager.showToastMessage('danger', 'Error while retrieving user info');
      this.close();
    });
  }

  close() {
    if (this.closeEvent) {
      this.closeEvent.emit();
    }
  }

  onSubmit() {
    this.loading = true;

    const objVals = this.deviceForm.value;
    this.device.id = objVals.id; //necessario perché edit e new non ritornano gli oggetti del db

    for (const city of this.cities) {
      if (objVals.cityName == city.name) {
        this.device.cityId = city.id;
        objVals.cityId = city.id;
        break;
      }
    }

    this._deviceSvc.edit(this.device.id, objVals).subscribe(() => {
      this.loading = false;
      this.update();
      const msg = this.translate.instant('Device modified successfully');
      this.widgetsManager.showToastMessage('success', msg);
    }, err => {
      this.loading = false;
      console.log(err);
      this.widgetsManager.showToastMessage('danger', 'Error while adding device');
    });
  }

  public getLabelDeviceType(cod: DeviceType): string {
    return Device.getLabelDeviceType(cod);
  }
}
