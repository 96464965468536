import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertController, NavController, NavParams, PopoverController, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {SettingsmanagerService} from '../../services/settingsmanager.service';
import {WsmanagerService} from '../../services/wsmanager.service';

@Component({
  selector: 'app-change-state-popover',
  templateUrl: './change-state-popover.page.html',
  styleUrls: ['./change-state-popover.page.scss'],
})
export class ChangeStatePopoverPage implements OnInit {

  public isSpinnerVisible  = false;

  public deviceId;
  public deviceName;
  public deviceState;

  public deviceForm: FormGroup;
  public validation_messages = null;
  public states = null;

  constructor(
    private navParams: NavParams,
    private translate: TranslateService,
    private settingsManager: SettingsmanagerService,
    private formBuilder: FormBuilder,
    private wsManager: WsmanagerService,
    private toastController: ToastController,
    public alertController: AlertController,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.deviceId = this.navParams.get('deviceId');
    this.deviceName = this.navParams.get('deviceName');
    this.deviceState = this.navParams.get('deviceState');

    this.states = this.settingsManager.getStatesOptions();

    this.deviceForm = this.formBuilder.group({
      stateId: [this.deviceState, Validators.compose([
        Validators.required
      ])]
    });

    this.validation_messages = {
      'stateId': [
        { type: 'required', message: 'Field is required.'},
      ]
    };
  }

  showSpinner() {
    this.isSpinnerVisible = true;
  }

  hideSpinner() {
    this.isSpinnerVisible = false;
  }

  async onSubmit(values) {

    console.log('---------------------> EDIT VALUES: ', values);
    console.log('---------------------> EDIT Device: ', this.deviceId);
    console.log('---------------------> EDIT Device: ', this.deviceName);

    this.showSpinner();

    this.wsManager.changeDeviceState(
        this.deviceId,
        this.deviceName,
        values.stateId
    ).subscribe(data => {
      console.log(data);
      this.hideSpinner();
      const confirmMsg = this.translate.instant('State successfully updated');
      this.showMessage('success', confirmMsg);
      this.closePopover();
    }, err => {
      this.hideSpinner();
      const errorMsg = this.translate.instant('Error while updating device state');
      this.showMessage('danger', errorMsg);
    });
  }

  async showMessage(messageSeverity: string, messageText: string, messageDuration: number = 2000) {
    const toast = await this.toastController.create({
      message: messageText,
      translucent: true,
      color: messageSeverity,
      duration: messageDuration,
      position: 'bottom',
      mode: 'md',
      cssClass: messageSeverity
    });
    toast.present();
  }

  async closePopover(reloadParent = false) {
    await this.popoverController.dismiss(reloadParent);
  }
}
