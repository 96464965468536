import { Injectable } from '@angular/core';

// BS modules
import {ToastController, AlertController, LoadingController} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class WidgetsmanagerService {

  constructor(
              private toastController: ToastController,
              private alertController: AlertController,
              private loadingController: LoadingController,
              private translate: TranslateService) {
    // console.log('@@@ Constructor WidgetsmanagerService');
  }


  /**
   * Shows an alert
   */
  async showAlert(
    header: string,
    message: string,
    buttons= [],
    inputs= []

  ){
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: buttons,
      inputs: inputs
    });
    await alert.present();
  }

  /**
   * Shows a toast message
   */
  async showToastMessage(
    messageSeverity: string,
    messageText: string,
    messageDuration = 2000,
    messageHeader= '',
    messageButtons= [],
    messageShowClose= false,
    messageCloseButtonText= 'Close',
    messagePosition: 'top' | 'bottom' | 'middle'= 'bottom',
    messageMode: 'ios' | 'md' = 'md',
    messageTranslucent= true
  ) {
    const toast = await this.toastController.create({
      header: messageHeader,
      message: messageText,
      showCloseButton: messageShowClose,
      translucent: messageTranslucent,
      color: messageSeverity,
      closeButtonText: messageCloseButtonText,
      duration: messageDuration,
      position: messagePosition,
      mode: messageMode,
      buttons: messageButtons
    });
    toast.present();
  }
  async openLoader(messageTextPar= '',
                   cssClassPar= 'default_loader_class',
                   translucentPar = true,
                   backdropDismissPar = false
  ) {
    const loader = await this.loadingController.create({
      backdropDismiss: backdropDismissPar,
      translucent: translucentPar,
      cssClass: cssClassPar,
      message: messageTextPar
    });
    await loader.present();
    return loader;
  }

  async closeLoader() {
    return await this.loadingController.dismiss().then(() => console.log('@@@@@@@@@@@dismissed@@@@@@@@@@@@@@@@@'));
  }

}
