import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Hub } from '../classes';
import {SettingsmanagerService} from './settingsmanager.service';

@Injectable({
  providedIn: 'root'
})
export class HubService {

  private _url: string;

  constructor(
    private _http: HttpClient,
    private settingsManager: SettingsmanagerService
  ) {
    this._url =  this.settingsManager.getBaseWsUrl();
  }

  get(id: number): Observable<Hub> {
    return this._http.get<Hub>(`${this._url}/hub/${id}`).pipe(
        map(obj => new Hub(obj))
    );
  }

  create(objHub: Hub): Observable<Hub> {
    return this._http.post<Hub>(`${this._url}/hub/`, objHub).pipe(
      map(obj => new Hub(obj))
    );
  }

  edit(id: number, objHub: Hub): Observable<any> {
    return this._http.patch<any>(`${this._url}/hub/${id}`, objHub);
  }

  delete(id: number): Observable<any> {
    return this._http.delete<any>(`${this._url}/hub/${id}`);
  }
}
