import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';

import * as TRANSL_EN from './i18n/en.json';
import * as TRANSL_IT from './i18n/it.json';

@Injectable({
    providedIn: 'root'
})
export class CustomLoader implements TranslateLoader {
    constructor(
        private _http: HttpClient
    ) { }

    /**********************************************************************************************
        gestione dinamica traduzioni

        la traduzioni sono nel file 'src/gs_assets/i18n/<lingua>.json',
        se esiste file src/assets/i18n/<lingua>_custom.json allora si accodano a quelle esistenti
    ***********************************************************************************************/
    getTranslation(lang: string): Observable<any> {
        let objTransl = undefined;
        switch(lang) {
            case 'it':
                objTransl = (TRANSL_IT as any).default;
                break;
            case 'en':
                objTransl = (TRANSL_EN as any).default;
                break;
        }

        return this._http.get(`./assets/i18n/${lang}_custom.json`).pipe(
            map(objCustom => _.merge(objTransl, objCustom)),
            catchError(() => of(objTransl))
        );
    }
}
