export enum GrantPlatformType {
    BUSINESS_CUSTOMER_MANAGEMENT = 'BUSINESS_CUSTOMER_MANAGEMENT',
    RENTAL_BOOKING = 'RENTAL_BOOKING',
    VOUCHER_MANAGEMENT = 'VOUCHER_MANAGEMENT',
    PROMOTIONAL_RATES = 'PROMOTIONAL_RATES',
    SUBSCRIPTION_MANAGEMENT = 'SUBSCRIPTION_MANAGEMENT',
    CUSTOM_RATE = 'CUSTOM_RATE',
    FEEDBACK_MANAGEMENT = 'FEEDBACK_MANAGEMENT',
    OUTSOURCING_MANAGEMENT = 'OUTSOURCING_MANAGEMENT',
    FLEET_MANAGEMENT = 'FLEET_MANAGEMENT',
    PAYMENT_STRIPE = 'PAYMENT_STRIPE',
    PAYMENT_SATISPAY = 'PAYMENT_SATISPAY',
    PAYMENT_NEXI = 'PAYMENT_NEXI',
    PAYMENT_PAYPAL = 'PAYMENT_PAYPAL',
    REPORT_MANAGEMENT = 'REPORT_MANAGEMENT',
    ERP_INTEGRATION = 'ERP_INTEGRATION',
    DRIVER_LICENSE_ONBOARDING = 'DRIVER_LICENSE_ONBOARDING',
    DOCUMENTS_MANAGEMENT = 'DOCUMENTS_MANAGEMENT',
    FIREBASE_MANAGEMENT = 'FIREBASE_MANAGEMENT',
    EVENTS_MANAGEMENT = 'EVENTS_MANAGEMENT'
}

export interface GrantPlatform {
    name: GrantPlatformType;
    enabled: boolean;
}
