import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PricingPlan } from 'src/app/classes';
import { TimeSlot } from 'src/app/classes/TimeSlot';
import { WidgetsmanagerService } from 'src/app/services/widgetsmanager.service';
import { WsmanagerService } from 'src/app/services/wsmanager.service';

const MS_PER_WEEK = 7*24*60*60*1000;
const MS_START_WEEK = 0;

@Component({
  selector: 'app-time-slots',
  templateUrl: './time-slots.component.html',
  styleUrls: ['./time-slots.component.scss'],
})

export class TimeSlotsComponent implements OnInit {

  @Input('closeEvent')
  public closeEvent: EventEmitter<any>;

  @Input('pricingPlan')
  public pricingPlan: PricingPlan;

  public loading = false;
  public timeSlotForm: FormGroup;
  public validate_messages: any; 
  public minuteValues = [0,5,10,15,20,25,30,35,40,45,50,55];

  constructor(
    private widgetManager: WidgetsmanagerService,
    private wsManager: WsmanagerService,
    private translate: TranslateService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {

    this.timeSlotForm = this.formBuilder.group({
      startMillis: ['', [
        Validators.required
      ]],
      startDay: ['', [
        Validators.required
      ]],
      endMillis: ['', [
        Validators.required
      ]],
      endDay: ['', [
        Validators.required
      ]],
      unlockDiscount: ['', [
        Validators.required,
        Validators.min(0),
        Validators.max(100)
      ]],
      activeDiscount: ['', [
        Validators.required,
        Validators.min(0),
        Validators.max(100)
      ]],
      breakDiscount: ['', [
        Validators.required,
        Validators.min(0),
        Validators.max(100)
      ]],
    });

    this.validate_messages = {
      startMillis: [
        { type: 'required', message: 'Field is required' },
      ],
      startDay: [
        { type: 'required', message: 'Field is required' },
      ],
      endMillis: [
        { type: 'required', message: 'Field is required' },
      ],
      endDay: [
        { type: 'required', message: 'Field is required' },
      ],
      unlockDiscount: [
        { type: 'required', message: 'Field is required.' },
        { type: 'min', message: 'Have to be between 0 and 100.' },
        { type: 'max', message: 'Have to be between 0 and 100.' }
      ],
      activeDiscount: [
        { type: 'required', message: 'Field is required.' },
        { type: 'min', message: 'Have to be between 0 and 100.' },
        { type: 'max', message: 'Have to be between 0 and 100.' }
      ],
      breakDiscount: [
        { type: 'required', message: 'Field is required.' },
        { type: 'min', message: 'Have to be between 0 and 100.' },
        { type: 'max', message: 'Have to be between 0 and 100.' }
      ],
    };

    this.update();
  }

  // Get pricing plan timeslots
  update() {
    this.loading = true;
    this.wsManager.getPricingPlanTimeSlot(this.pricingPlan.id)
      .subscribe(data => {
        this.loading = false;
        for(let timeslot of data){
          if (timeslot.endMillis == MS_PER_WEEK){
            timeslot.endMillis = MS_START_WEEK;
          }
        }
        this.pricingPlan.timeSlots = data;
      }, err => {
        console.log(err.error);
        this.widgetManager.showToastMessage('danger', this.translate.instant('Error while retrieving pricing plan info'));
      });
  }

  // New timeslot
  onSubmit(values) {
    const startMillis = this.displayDayToMillis(values.startDay) + this.displayTimeToMillis(new Date(values.startMillis));
    let endMillis = this.displayDayToMillis(values.endDay) + this.displayTimeToMillis(new Date(values.endMillis));

    // Se endMillis è lunedì 00:00 significa che il timeslot finisce alla mezzanotte della domenica
    if (endMillis === MS_START_WEEK){
      endMillis = MS_PER_WEEK;
    }

    if (startMillis >= endMillis){
      this.widgetManager.showToastMessage('danger', this.translate.instant('Time slot end have to be after start'));
      return;
    }

    const timeSlot = new TimeSlot({
      startMillis: startMillis,
      endMillis: endMillis,
      unlockDiscount: values.unlockDiscount/100,
      activeDiscount: values.activeDiscount/100,
      breakDiscount: values.breakDiscount/100,
      pricingPlanId: this.pricingPlan.id
    });

    this.loading = true;
    this.wsManager.newTimeSlot(timeSlot)
    .subscribe( data => {
      this.loading = false;
      this.widgetManager.showToastMessage('success', this.translate.instant('Time slot added successfully'));
      this.timeSlotForm.reset();
      this.update();
    }, err => {
      this.loading = false;
      console.log(err.error);
      this.widgetManager.showToastMessage('danger', this.translate.instant('Error while adding time slot'));
    });
  }

  delete(id) {
    this.loading = true;
    this.wsManager.deleteTimeSlot(id)
      .subscribe(() => {
        this.loading = false;
        this.update();
        this.widgetManager.showToastMessage('success', this.translate.instant('Time slot deleted successfully'));
      }, err => {
        this.widgetManager.showToastMessage('danger', this.translate.instant('Error while removing time slot'));
      });
  }

  millisToDisplayDay(millis: number) {
    const millisPerDay = 24*60*60*1000;
    switch (Math.floor(millis / millisPerDay)){
      case 0:
      case 7:
        return 'Monday'
      case 1:
        return 'Tuesday';
      case 2:
        return 'Wednesday';
      case 3:
        return 'Thursday';
      case 4:
        return 'Friday';
      case 5:
        return 'Saturday';
      case 6:
        return 'Sunday';
      default:
        return '';
    }
  }

  millisToDisplayTime(millis: number) {
    const mins = Math.floor((millis / (1000 * 60)) % 60);
    const hrs = Math.floor((millis / (1000 * 60 * 60)) % 24);

    const displayHrs = (hrs < 10) ? "0" + hrs : hrs;
    const displayMins = (mins < 10) ? "0" + mins : mins;

    return `${displayHrs}:${displayMins}`;
  }

  displayDayToMillis(day) {
    return parseInt(day) * 24 * 60 * 60 * 1000;
  }

  displayTimeToMillis(time) {
    const hrs = time.getHours();
    const mins = time.getMinutes();

    const hrsToMillis = hrs * 60 * 60 * 1000;
    const minsToMillis = mins * 60 * 1000;
    return hrsToMillis + minsToMillis;
  }

  validate(field){
    for (let validation of this.validate_messages[field]){
      if (this.timeSlotForm.get(field).hasError(validation.type) 
      && (this.timeSlotForm.get(field).dirty || this.timeSlotForm.get(field).touched)){
        return { res: true, message: validation.message };
      }
    }
    return { res: false, message: '' };
  }

  close() {
    if (this.closeEvent) {
      this.closeEvent.emit();
    }
  }
}
