export class Company {
    public id: number;
    public vatCode: string;
    public taxCode: string;
    public businessName: string;
    public paymentUniqueCode: string;
    public address: string;
    public cap: string;
    public phone: string;
    public emailPec: string;
    public email: string;
    public city: string;
    public prov: string;
    public nation_iso: string;

    constructor(obj?: any) {
        if (!obj) {
            return;
        }
        Object.assign(this, obj);
    }
}
