import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, NavParams, PopoverController,
  NavController, ToastController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// BS modules
import { City, PricingPlan} from './../../classes';

// BS service providers
import { SettingsmanagerService } from './../../services/settingsmanager.service';
import { WsmanagerService } from '../../services/wsmanager.service';

declare var google;

@Component({
  selector: 'app-citydetail',
  templateUrl: './citydetail.page.html',
  styleUrls: ['./citydetail.page.scss'],
})
export class CitydetailPage implements OnInit {


  public isSpinnerVisible  = false;
  public isScooterSectionVisible = false;

  public city: City = null;
  public cityForm: FormGroup;
  public newCity =  false;
  public validation_messages = null;
  public currentcityName = null;
  public currentCityId = null;
  public pricingPlans: PricingPlan[];
  public colorList = [];

  constructor(private navParams: NavParams,
              private translate: TranslateService,
              private settingsManager: SettingsmanagerService,
              private navCtrl: NavController,
              private formBuilder: FormBuilder,
              private wsManager: WsmanagerService,
              private toastController: ToastController,
              public alertController: AlertController,
              private popoverController: PopoverController) {
    // console.log('@@@CONSTRUCTOR-CITY DETAIL');
  }

  ngOnInit() {
    this.colorList = this.settingsManager.getAreaColors();
    this.loadPricingPlans();

    // Retrieving page parameters
    const cityParameterString = this.navParams.get('city');
    const cityCoortdinatesString = this.navParams.get('city_coordinates') || '';
    this.newCity = this.navParams.get('new_city') === 1 ? true : false;
    this.currentCityId = this.settingsManager.getCitySetting();
    this.wsManager.fetchCityData(this.currentCityId)
        .subscribe(async data => {
          // console.log('retrieveCityData:', data);
           this.city = await new City(data);
          this.currentcityName = this.city.name;
          // console.log('-->cityCoortdinatesString:', cityCoortdinatesString);
          // Set default values after loading them from the server
          this.cityForm.controls['id'].setValue(this.city.id);
          this.cityForm.controls['name'].setValue(this.city.name);
          this.cityForm.controls['description'].setValue(this.city.description);
          console.log('-->cityCoortdinatesString  new zone1:', this.city.coordinates);
          if ((this.city.coordinates === null) ||  (this.city.coordinates === '')) {
            // new zone
            // console.log('-->cityCoortdinatesString  new zone2:', cityCoortdinatesString);
            this.cityForm.controls['coordinates'].setValue(cityCoortdinatesString);
            this.city.coordinates = cityCoortdinatesString;
          } else {
            // editing old zone
            this.cityForm.controls['coordinates'].setValue(this.city.coordinates);
          }
          // Calcolo l'area della zona se non è presente
          const zoneArea = this.getZoneArea(this.city.coordinates);
          this.city.area = zoneArea || 0;
          this.cityForm.controls['area'].setValue(this.city.area);
          this.cityForm.controls['center_latitude'].setValue(this.city.center_latitude);
          this.cityForm.controls['center_longitude'].setValue(this.city.center_longitude);
          this.cityForm.controls['code'].setValue(this.city.code);
          this.cityForm.controls['discount_on_active_fee'].setValue(this.city.discount_on_active_fee);
          this.cityForm.controls['discount_on_break_fee'].setValue(this.city.discount_on_break_fee);
          this.cityForm.controls['discount_on_unlock_fee'].setValue(this.city.discount_on_unlock_fee);   
          this.cityForm.controls['discount_on_booking_fee'].setValue(this.city.discount_on_booking_fee);         
          const park_enabled_string = this.city.park_enabled ? 'true' : 'false' ;
          const isVisible_string = this.city.isVisible ? 'true' : 'false' ;
          const warning_string = this.city.warning_chiusura_attivo ? 'true' : 'false' ;
          const avviso_sanzioni_string = this.city.avviso_sanzioni_attivo ? 'true' : 'false' ;
          const isDocumentRequired_string = this.city.isDocumentRequired ? 'true' : 'false' ;
          const isFullSubscriptionRequired_string = this.city.isFullSubscriptionRequired ? 'true' : 'false' ;
          this.cityForm.controls['park_enabled'].setValue(park_enabled_string);
          this.cityForm.controls['isVisible'].setValue(isVisible_string);
          this.cityForm.controls['warning_chiusura_attivo'].setValue(warning_string);
          this.cityForm.controls['avviso_sanzioni_attivo'].setValue(avviso_sanzioni_string);
          this.cityForm.controls['isDocumentRequired'].setValue(isDocumentRequired_string);
          this.cityForm.controls['isFullSubscriptionRequired'].setValue(isFullSubscriptionRequired_string);
          this.cityForm.controls['commandTypeId'].setValue(String(this.city.commandTypeId));
          this.cityForm.controls['color'].setValue(this.city.color);
          // this.cityForm.controls['pricingPlanId'].setValue(this.city.pricingPlanId);
          this.cityForm.controls['scooter_coordinates'].setValue(this.city.scooter_coordinates);
          this.cityForm.controls['scooterauto_parking_enabled'].setValue(this.city.scooterauto_parking_enabled? 'true' : 'false');
          // Calcolo l'area della zona se non è presente
          const scooterArea = this.getZoneArea(this.city.scooter_coordinates);
          this.city.scooter_area = scooterArea || 0;
          this.cityForm.controls['scooter_area'].setValue(this.city.scooter_area);
          this.cityForm.controls['scooter_area_color'].setValue(this.city.scooter_area_color);
        }, err => {
          console.log('ERR-retrieveCityData:', err);
          return null;
        });


    /* City Form report */
    this.cityForm = this.formBuilder.group({
      id: [null, Validators.compose([  ])],
      name: [null, Validators.compose([  ])],
      description: [this.city.description || '', Validators.compose([Validators.required, Validators.maxLength(512)])],
      coordinates: ['', Validators.compose([Validators.required])],
      center_latitude: [0, Validators.compose([Validators.required])],
      center_longitude: [0, Validators.compose([Validators.required])],
      code: [0, Validators.compose([Validators.required])],
      commandTypeId: ['', Validators.compose([Validators.required])],
      discount_on_active_fee: [0, Validators.required],
      discount_on_break_fee: [0, Validators.required],
      discount_on_unlock_fee: [0, Validators.required],
      discount_on_booking_fee: [0, Validators.required],
      park_enabled: ['', Validators.compose([Validators.required])],
      isVisible: ['', Validators.compose([Validators.required])],
      avviso_sanzioni_attivo: ['', Validators.compose([Validators.required])],
      warning_chiusura_attivo: ['', Validators.compose([Validators.required])],
      color: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])],
      isDocumentRequired: ['', Validators.compose([Validators.required])],
      isFullSubscriptionRequired: ['', Validators.compose([Validators.required])],
      area: [''],
      // pricingPlanId: [null, Validators.compose([Validators.required])],
      scooter_coordinates: [''],
      scooterauto_parking_enabled: ['false'],
      scooter_area: [''],
      scooter_area_color: ['']
    });

    this.validation_messages = {
      'description': [
        {type: 'required', message: 'This field is required.'},
        {type: 'maxlength', message: 'This field must have at most 512 characters.'},
      ],
      'coordinates': [
        {type: 'required', message: 'This field is required.'}
      ],
      'center_latitude': [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      'center_longitude': [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      'code': [
        {type: 'required', message: 'This field is required.'},
      ],
      'commandTypeId': [
        {type: 'required', message: 'This field is required.'},
      ],
      'discount_on_active_fee': [
        {type: 'required', message: 'This field is required.'},
      ],
      'discount_on_break_fee': [
        {type: 'required', message: 'This field is required.'},
      ],
      'discount_on_unlock_fee': [
        {type: 'required', message: 'This field is required.'},
      ],
      'discount_on_booking_fee': [
        {type: 'required', message: 'This field is required.'},
      ],
      'free_unlock': [
        {type: 'required', message: 'This field is required.'},
      ],
      'park_enabled': [
        {type: 'required', message: 'This field is required.'},
      ],
      'isVisible': [
        {type: 'required', message: 'This field is required.'},
      ],
      'avviso_sanzioni_attivo': [
        {type: 'required', message: 'This field is required.'},
      ],
      'warning_chiusura_attivo': [
        {type: 'required', message: 'This field is required.'},
      ],
      'color': [
        {type: 'required', message: 'This field is required.'},
        {type: 'minlength', message: 'This field must have just 6 characters.'},
        {type: 'maxlength', message: 'This field must have just 6 characters.'},
      ],
      'isDocumentRequired': [
        {type: 'required', message: 'This field is required.'},
      ],
      'isFullSubscriptionRequired': [
        {type: 'required', message: 'This field is required.'},
      ],
      // 'pricingPlanId': [
      //   {type: 'required', message: 'This field is required.'},
      // ],
    };


  }

  loadPricingPlans() {
    this.isSpinnerVisible = true;
    this.wsManager.getPricingPlans().subscribe(items => {
      console.log(items);
      this.pricingPlans = items;
      this.isSpinnerVisible = false;
    });
  }


  async onSubmit(values) {
    console.log('@@ CITY submit: ', values, this.newCity);
    this.showSpinner();
    this.wsManager.editCity(values)
      .subscribe((response) => {
          console.log('onSubmit OK', response);
          this.hideSpinner();
          const actiondMsg = this.translate.instant('City edited successfully.');
          this.showMessage('success', actiondMsg);
          this.closePopover(true);
        },
        (err) => {
          console.log('onSubmit KO', err.status, err);
          this.hideSpinner();
          let actiondMsg;
          actiondMsg = this.translate.instant('Error editing the city.');
          this.showMessage('danger', actiondMsg);
        });
  }

  private getZoneArea(coordinates) {
    let zoneArray = [];
    const areaPathArray = [];
    // Separo i vertici del poligopno
    if (coordinates) {
      zoneArray = coordinates.split(';');
      // console.log('@@@@zoneArray:', zoneArray);
      zoneArray.forEach(element => {
        // Separo le coordinate dei vertici del poligono
        const arr = element.split(',');
        areaPathArray.push(new google.maps.LatLng(arr[0], arr[1]));
      });
      // console.log('@@@@areaPathArray:', areaPathArray);
      const zoneArea = google.maps.geometry.spherical.computeArea(areaPathArray).toFixed(2);
      console.log('@@@@CALCOLA AREA --> getZoneArea:', zoneArea);
      return zoneArea;
    } else {
      return 0;
    }
  }


  showSpinner() {
    this.isSpinnerVisible = true;
  }

  hideSpinner() {
    this.isSpinnerVisible = false;
  }

  async closePopover(reloadParent = false) {
    if (reloadParent) {
      this.wsManager.fetchCitiesData().subscribe(async data => {
        this.settingsManager.updateCities(data);
        await this.popoverController.dismiss(reloadParent);
      }, err => {
        console.log('@@@ ERR-updateCitySettings', err);
      });
    }
    await this.popoverController.dismiss(reloadParent);
  }

  async showMessage(messageSeverity: string, messageText: string, messageDuration: number = 2000) {
    const toast = await this.toastController.create({
      message: messageText,
      translucent: true,
      color: messageSeverity,
      duration: messageDuration,
      position: 'bottom',
      mode: 'md',
      cssClass: messageSeverity
    });
    toast.present();
  }

  deleteCityZone() {
    this.presentAlertConfirm(this.currentCityId);
  }

  async presentAlertConfirm(cityId: number) {
    const alert = await this.alertController.create({
      header: this.translate.instant('Are you sure to proceed?'),
      // message: 'Message <strong>text</strong>!!!',
      buttons: [
        {
          text: this.translate.instant('Cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Ok',
          handler: () => {
            console.log('Delete zone');
            this.wsManager.resetCityZone(cityId).subscribe(res => {
              const msgOK = this.translate.instant('Zone removed successfully.');
              this.showMessage('success', msgOK);
              this.closePopover(true);
            }, err => {
              const msgKO = this.translate.instant('Error removing the zone.');
              this.showMessage('danger', msgKO);
            });
          }
        }
      ]
    });

    await alert.present();
  }

  showScooterSection(event) {
    console.log('showScooterSection', this.isScooterSectionVisible);
    this.isScooterSectionVisible = true;
    event.target.disable = true;
  }

  hideScooterSection(event) {
    console.log('hideScooterSection', this.isScooterSectionVisible);
    this.isScooterSectionVisible = false;
    event.target.disable = true;
  }

}
