import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, NavParams, PopoverController,
  NavController, ToastController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// BS modules
import {FcmMessage, FcmTopic} from './../../classes';

// BS service providers
import { SettingsmanagerService } from './../../services/settingsmanager.service';
import { WsmanagerService } from '../../services/wsmanager.service';


@Component({
  selector: 'app-send-fcm-notification',
  templateUrl: './send-fcm-notification.page.html',
  styleUrls: ['./send-fcm-notification.page.scss'],
})
export class SendFcmNotificationPage implements OnInit {

  public isSpinnerVisible  = false;

  public  message: FcmMessage = null;
  public messageForm: FormGroup;
  public validation_messages = null;
  public fcmTopics: FcmTopic[];

  constructor(private navParams: NavParams,
              private translate: TranslateService,
              private settingsManager: SettingsmanagerService,
              private navCtrl: NavController,
              private formBuilder: FormBuilder,
              private wsManager: WsmanagerService,
              private toastController: ToastController,
              public alertController: AlertController,
              private popoverController: PopoverController) {
    this.fetchTopics();
  }

  ngOnInit() {


    /* Message Form  */
    this.messageForm = this.formBuilder.group({

      title: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(256),
        ])],

      description: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(1024),
        ])],

      topic: ['',
        Validators.compose([
          Validators.required
        ])],
    });

    this.validation_messages = {
      'title': [
        {type: 'required', message: 'This field is required.'},
        {type: 'minlength', message: 'This field must have at least 3 characters.'},
        {type: 'maxlength', message: 'This field must have at most 128 characters.'},
      ],
      'description': [
        {type: 'required', message: 'This field is required.'},
        {type: 'minlength', message: 'This field must have at least 3 characters.'},
        {type: 'maxlength', message: 'This field must have at most 512 characters.'},
      ],
      'topic': [
        {type: 'required', message: 'This field is required.'}
      ],
    };
  }

  showSpinner() {
    this.isSpinnerVisible = true;
  }

  hideSpinner() {
    this.isSpinnerVisible = false;
  }

  async closePopover(reloadParent = false) {
    await this.popoverController.dismiss(reloadParent);
  }


  async onSubmit(values) {
    console.log('@@ Message submit: ', values);
    this.showSpinner();
    const notification = new FcmMessage();
    notification.topicId = values.topic;
    notification.title = values.title.trim();
    notification.description = values.description.trim();

    const alert = await this.alertController.create({
      header: this.translate.instant('Are you sure to proceed?'),
      // message: 'Message <strong>text</strong>!!!',
      buttons: [
        {
          text: this.translate.instant('Dismiss'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.hideSpinner();
          }
        }, {
          text: 'Ok',
          handler: () => {
            this.wsManager.sendFcmNotification(notification)
                .subscribe(data => {
                  console.log(data);
                  this.showMessage('success', this.translate.instant('Notification successfully sent.'));
                  this.hideSpinner();
                  this.closePopover(true);
                }, err => {
                  this.showMessage('error', this.translate.instant('Error sending the notification.'));
                  this.hideSpinner();
                });
          }
        }
      ]
    });
    await alert.present();
  }

  async showMessage(messageSeverity: string, messageText: string, messageDuration: number = 2000) {
    const toast = await this.toastController.create({
      message: messageText,
      translucent: false,
      color: messageSeverity,
      duration: messageDuration,
      position: 'bottom',
      mode: 'md',
      cssClass: messageSeverity
    });
    toast.present();
  }

  fetchTopics() {
    this.wsManager.fetchFcmTopics()
        .subscribe(items => {
          this.fcmTopics = items;
        });
  }

}
