import * as _ from 'lodash';
import { UserDocument, Rental, PricingPlan, SeasonTicket, City, Ente } from './WSClasses';
import { Company } from './Company';
import { PaymentMethod } from './PaymentMethod';

export enum DriverDataStatus {
    Accepted = 'Accepted',
    Pending = 'Pending',
    Rejected = 'Rejected'
}

export class DriverData {
    id: number;
    status: DriverDataStatus;
    licenseCode: string;
    licenseReleasedBy: string;
    licenseExpiryDate: Date;

    constructor(values?: any) {
        if (!values) {
            return;
        }
        Object.assign(this, values);
        if (values.licenseExpiryDate) {
            this.licenseExpiryDate = new Date(values.licenseExpiryDate);
        }
    }
}

export enum RoleIdEnum {
    ADMIN = 1,
    CITY_MANAGER = 2,
    OP_MANAGER = 3,
    CHARGER = 4,
    USER = 5,
    SECURITY = 6,
    GUEST = 7,
    OPERATOR = 8,
    OPENDATA = 9
}

export enum GenderEnum {
    Male = 'Male',
    Female = 'Female'
}

export class User {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    city: string;
    social_security_number: string;
    country_iso: string;
    telephone: string;
    tel_country_iso: string;
    address: string;
    resid_prov: string;
    resid_nation_iso: string;
    attributes: string;
    search_string: string;
    roleId: RoleIdEnum;
    rentalsNumber: number;
    rentalsPrice: number;
    createdAt: string;
    deletedAt: string;
    email_confirmed: boolean;
    account_confirmed: boolean;
    blocked: boolean;
    odometer: number;
    payment_methods: PaymentMethod[];
    documents: UserDocument[];
    rentals: Rental[];
    usage_conditions_accepted: boolean;
    privacy_conditions_accepted: boolean;
    unfair_terms_accepted: boolean;
    registrationCity: string;
    gender: GenderEnum;
    birth_place: string;
    birth_date: string;
    cap: number;
    pricingPlan: PricingPlan;
    fullSubscription: boolean;
    tempPricingPlan: PricingPlan;
    active_subscriptions: SeasonTicket[];
    wallet_amount: number;
    assignedCities: City[];
    debt: number;
    company: Company;
    driverData: DriverData;
    ente: Ente;
    external_code: string;
    stripeId: string;
    onboarding_completed: boolean;
    licenseStatus: string;
    creditCardInserted: boolean;
    userDeleted: string;


    constructor(values: any = {}) {
        Object.assign(this, values);
        this.search_string = this.id + ' ' + this.email + ' ' + this.firstName + ' ' + this.lastName + ' ' + this.city;
        this.id = Number(this.id);
        if (this.rentals) {
            this.rentalsPrice = _(this.rentals).sumBy('amount');
            this.rentalsNumber = this.rentals.length;
        } else {
            if (!this.rentalsNumber) {
                this.rentalsNumber = 0;
            } else {
                this.rentalsNumber = Number(this.rentalsNumber);
            }
            if (!this.rentalsPrice) {
                this.rentalsPrice = 0;
            } else {
                this.rentalsPrice = Number(this.rentalsPrice);
            }
        }
        if (values.company) {
            this.company = new Company(values.company);
        }
        if (values.driverData) {
            this.driverData = new DriverData(values.driverData);
        }
        // console.log('---->USER-this.search_string:', this.search_string);
    }
}
