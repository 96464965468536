import { User } from '../User';
import { ConvCode } from './Conv';
import { ConvUserData } from './ConvUserData';

export class ConvInvocation {
    public id: number;
    public user: User;
    public enabled: boolean;
    public verified: boolean;
    public blocked: boolean;
    public convCode: ConvCode;
    public convUserDatas: ConvUserData[];

    constructor(obj?: any) {
        if (!obj) {
            return;
        }
        Object.assign(this, obj);
    }
}