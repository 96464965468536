export function roundTo(amount: number, precision = 2): number {
    let negative = false;
    let n = amount;    
    if(amount < 0) {
      negative = true;
      n = n*-1;
    }
    const multiplicator = Math.pow(10, precision);
    n = Math.round(n * multiplicator) / multiplicator;
    if (negative) {    
        n = n*-1;
    }
    return n;
}