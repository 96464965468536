import { WsmanagerService } from './../../services/wsmanager.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {Warning, WarningType} from '../../classes';
import {NavParams, PopoverController} from '@ionic/angular';
import {SettingsmanagerService} from '../../services/settingsmanager.service';
import { WidgetsmanagerService } from 'src/app/services/widgetsmanager.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-warning-popover',
  templateUrl: './warning-popover.page.html',
  styleUrls: ['./warning-popover.page.scss'],
})
export class WarningPopoverPage implements OnInit {

  warningInfo: Warning = null;
  filteredWarnings: LocalDataSource
  warningTypeInfo: WarningType[];
  public confirmMsg = this.translate.instant('Operation successfully completed');
  public errorMsg = this.translate.instant('Error while completing operation');
  constructor(
      private navParams: NavParams,
      private popoverController: PopoverController,
      private wsManager: WsmanagerService,
      private _widgSvc: WidgetsmanagerService,
      private translate: TranslateService,
      private settingsManager: SettingsmanagerService
  ) {
    console.log(this.navParams);
    this.warningInfo = this.navParams.get('warning_data');
    console.log(this.navParams.get('filteredSource'));
    this.filteredWarnings = this.navParams.get('filteredSource');
    this.warningTypeInfo = this.warningInfo.warningtypes;
  }

  ngOnInit() {

  }

  public setAsManaged(): void {
    this.wsManager.setWarningAsManaged(this.warningInfo.id).subscribe(res => {
      this._widgSvc.showToastMessage('success', this.confirmMsg);
      this.closePopover('managed')
    }, () => {
      this._widgSvc.showToastMessage('danger', this.errorMsg);
    });

  }

  downloadFileReq(){
    this.wsManager.downloadWarning(this.warningInfo.id).subscribe(res => {
      this._widgSvc.showToastMessage('success', this.confirmMsg);
    }, () => {
      this._widgSvc.showToastMessage('danger', this.errorMsg);
    });
  }

  closePopover(reason?) {
    this.popoverController.dismiss(reason);
  }

}
