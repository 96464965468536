import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, NavParams, PopoverController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

// BS service providers
import { SettingsmanagerService } from '../../services/settingsmanager.service';


@Component({
  selector: 'app-view-area',
  templateUrl: './view-area.component.html',
  styleUrls: ['./view-area.component.scss'],
})
export class ViewAreaComponent implements OnInit {

  public isCity = false;
  public isZone = false;
  public showLimit = true;

  public areaData: any = null;

  public areaId = null;
  public areaColor = null;
  public areaName = null;
  public areaDescription = null;
  public areaCommanTypeId = null;
  public areaSpeedLimit = null;
  public areaParkingEnabled = null;
  public areaFreeUnlock = null;
  public area;
  public areaActiveFee = null;
  public areaUnlockFee = null;
  public areaBreakFee = null;
  public areaDiscountOnTotal = null;
  public areaDiscountOnFee = null;

  public zoneSpeedIconPath = null;

  constructor(
    private navParams: NavParams,
    private settingsManager: SettingsmanagerService,
    private popoverController: PopoverController) {
  }

  ngOnInit() {
    const isCityPar: boolean = this.navParams.get('isCity');
    const itemIdPar: number  = this.navParams.get('itemId');
    const itemDataObject: object  = JSON.parse(this.navParams.get('item_data'));
    console.log('###view Area-ngOnInit:', this.isCity, this.isZone, itemDataObject );

    const limitIconDirPath =  this.settingsManager.getLimitsIconDirPath();
    this.zoneSpeedIconPath = null;

    // Verifica se visualizzarre il dettaglio di una città o di una zona.
    if (isCityPar) {
      this.isCity =  true;
      this.isZone =  false;
      this.showLimit = false;
    } else {
      this.isCity =  false;
      this.isZone =  true;
      this.showLimit = true;
    }

    this.areaData = itemDataObject;
    this.areaId = itemIdPar;
    this.areaColor = '#' + this.areaData['color'];
    this.areaName = this.areaData['name'];
    this.areaDescription = this.areaData['description'];
    this.areaCommanTypeId = this.areaData['commandTypeId'];
    this.areaSpeedLimit = this.settingsManager.getSpeedLimitById(this.areaCommanTypeId);
    this.zoneSpeedIconPath = limitIconDirPath + this.settingsManager.getSpeedIconById(this.areaCommanTypeId);
    this.areaParkingEnabled = this.areaData['park_enabled'] ? 'Yes' : 'No' ;
    this.areaFreeUnlock = this.areaData['free_unlock'] ? 'Yes'  : 'No' ;
    this.areaActiveFee =  this.areaData['active_fee'] ? Number(this.areaData['active_fee'])  : 0 ;
    this.areaUnlockFee =  this.areaData['unlock_fee'] ? Number(this.areaData['unlock_fee'])  : 0 ;
    this.areaBreakFee =  this.areaData['break_fee'] ? Number(this.areaData['break_fee'])  : 0 ;
    this.areaDiscountOnTotal =  this.areaData['discount_on_fee'] ? Number(this.areaData['discount_on_fee'])  : 0 ;
    this.areaDiscountOnFee =  this.areaData['discount_on_total'] ? Number(this.areaData['discount_on_total'])  : 0 ;

    // // Chiusura automatica del popover
    // const timeZoneNotificationDuration = this.settingsManager.getDurationViewAreaNotification();
    // this.timerIdMapMarkers = setTimeout(() => {
    //   this.closePopover();
    // }, timeZoneNotificationDuration);

  }

  // ionViewWillLeave() {
  //   console.log('clear timeout');
  //   clearTimeout(this.windowTimeout);
  // }


  ionViewDidEnter() {
    // Modifico il colore del bordo del popover dinamicamente
    // document.getElementById('notification').style.border = '1px solid ' + this.areaColor;
  }

  closePopover() {
    this.popoverController.dismiss();
  }

}
