import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RentalActive } from 'src/app/classes';
import { WsmanagerService } from 'src/app/services/wsmanager.service';

@Component({
  selector: 'app-rental-active-detail',
  templateUrl: './rental-active-detail.component.html',
  styleUrls: ['./rental-active-detail.component.scss'],
})
export class RentalActiveDetailComponent implements OnInit {

  @Input()
  private rentalActiveId: number;

  @Input()
  private closeEvent: EventEmitter<any>;

  @Output()
  private goToMapEvent: EventEmitter<string>;

  @Output()
  private goToLogEvent: EventEmitter<string>;

  public rentalActive: RentalActive;
  public isLoading: boolean;

  constructor(
    private wsManager: WsmanagerService
  ) { }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.isLoading = true;
    this.wsManager.fetchRentalActiveDetail(this.rentalActiveId).subscribe(data => {
      this.rentalActive = data;
      console.log(this.rentalActive);
      this.isLoading = false;
    });
  }

  close() {
    this.closeEvent.emit();
  }

  goToMap() {
    this.goToMapEvent.emit(this.rentalActive.device.name);
  }

  goToLog() {
    this.goToLogEvent.emit(this.rentalActive.device.name);
  }

  duration2string(sec: number){
    let seconds = (sec % 60).toString();
    if (seconds.length == 1)
      seconds = "0" + seconds;
    let minutes = (Math.floor(sec / 60) % 60).toString();
    if (minutes.length == 1)
      minutes = "0" + minutes;
    let hours = (Math.floor(sec / 60 / 60)).toString();
    if (hours.length == 1)
      hours = "0" + hours;
    return `${hours}:${minutes}:${seconds}`;
  }

}
