import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input()
  public startButton: string;
  @Input()
  public customButtons;
  @Input()
  public loading;
  @Input()
  public title: string;

  constructor( ) { }

  ngOnInit() { }

}
