import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Rental, RentalReport } from '../classes';
import {SettingsmanagerService} from './settingsmanager.service';

export enum RentalResultCodeEnum {
    OK = 201,
    NOT_FOUND = 404,
    OUTSIDE_CITY_AREA = 420,
    NO_PARKING_ZONE = 421,
    NO_HUB_AREA = 422,
    ACTIVE_OR_STATUS_INVALID = 443,
    GENERIC_ERROR = 500
}

@Injectable({
  providedIn: 'root'
})
export class RentalService {

    private _url: string;

    constructor(
        private _http: HttpClient,
        private _settingsSvc: SettingsmanagerService
    ) { 
        this._url =  this._settingsSvc.getBaseWsUrl();
    }

    public closeRental(forcePark = false): Observable<any> {
        return this._http.post(`${this._url}/rental/stop`, {forcePark: forcePark});
    }

    public stopRentalAdmin(deviceId: number): Observable<RentalReport> {
        return this._http.delete<RentalReport>(`${this._url}/rental/${deviceId}/stop`);
    }

    public getTrip(historyId: number): Observable<Rental> {
        return this._http.get<Rental>(`${this._settingsSvc.getBaseWsUrl()}/rental/history/by-id/${historyId}`).pipe(
            map(obj => new Rental(obj))
        );
    }
}
