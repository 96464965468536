import { Device } from './Device';
 

export class Position {
    id: number;
    latitude: number;
    longitude: number;
    altitude: number;
    speed: number;
    satellites: number;
    sig_quality: number;
    hdop: number;
    pdop: number;
    device_time: number;
    device: Device;

    constructor(values: Object = {}) {
        Object.assign(this, values);
        if (this.device) {
            this.device = new Device(this.device);
        }
    }
}
