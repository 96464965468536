import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  template: '<ion-app><ion-router-outlet [swipeGesture]="false" main></ion-router-outlet></ion-app>',
})
export class AppComponent  {

  constructor() { }

}
