import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SeasonTicket } from 'src/app/classes';
import { WidgetsmanagerService } from 'src/app/services/widgetsmanager.service';
import { WsmanagerService } from 'src/app/services/wsmanager.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {

  @Input('subscription')
  public subscription: SeasonTicket;

  @Input('closeEvent')
  public closeEvent: EventEmitter<any>;

  @Output('subscriptionDeleted')
  public subscriptionDeleted: EventEmitter<SeasonTicket>;

  public loading = false; 

  constructor(
    private alertController: AlertController,
    private translate: TranslateService,
    private wsManager: WsmanagerService,
    private widgetManager: WidgetsmanagerService
  ) { }

  ngOnInit() {}

  async delete(){

    const alert = await this.alertController.create({
      header: this.translate.instant('Are you sure to proceed?'),
      // message: 'Message <strong>text</strong>!!!',
      buttons: [
        {
          text: this.translate.instant('Dismiss'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Ok',
          handler: () => {
            this.loading = true;
            this.wsManager.deleteSubscription(this.subscription.id).subscribe(res => {
              this.widgetManager.showToastMessage('success', 'Subscription deleted succesfully');
              this.loading = false;
              this.subscriptionDeleted.emit(this.subscription);
              this.close();
            }, err => {
              console.log(err);
              this.widgetManager.showToastMessage('danger', 'Error while removing subscription');
            });
          }
        }
      ]
    });

    await alert.present();
  }

  close(){
    if (this.closeEvent) {
      this.closeEvent.emit();
    }
  }
}
