import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpStatusCodesEnum } from 'src/app/utils/httpStatusCodesEnum';
import { AuthenticationmanagerService } from 'src/app/services/authenticationmanager.service';
import { Router } from '@angular/router';

@Injectable()
export class RestErrorAuthInterceptor implements HttpInterceptor {

    constructor(
        private _authService: AuthenticationmanagerService,
        private _router: Router,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === HttpStatusCodesEnum.UNAUTHORIZED) {
                this._router.navigateByUrl('/login');
                this._authService.logout();
            }
            return throwError(err);
        }));
    }
}
