import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TransactionData } from 'src/app/classes';
import { WidgetsmanagerService } from 'src/app/services/widgetsmanager.service';
import { WsmanagerService } from 'src/app/services/wsmanager.service';
import { ChargeReversalFormComponent } from '../charge-reversal-form/charge-reversal-form.component';

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss'],
})
export class TransactionDetailComponent implements OnInit {

  @Input()
  public transactionId: number;

  @Input()
  public closeEvent: EventEmitter<any>;

  public transaction: TransactionData;
  public chargeReversalLoading: boolean;

  constructor(
    private wsManager: WsmanagerService,
    private popoverController: PopoverController,
    private widgetsManager: WidgetsmanagerService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.fetchData();
    this.chargeReversalLoading = false;
  }

  fetchData() {
    this.wsManager.fetchSingleTransaction(this.transactionId).subscribe(tx => this.transaction = tx);
  }

  close() {
    if (this.closeEvent) {
      this.closeEvent.emit();
    }
  }

  getRentalTime() {
    const sec_num = Math.round(new Date(this.transaction.rentalHistory.stop_time).getTime() / 1000 - new Date(this.transaction.rentalHistory.start_time).getTime() / 1000);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    const seconds = sec_num - (hours * 3600) - (minutes * 60);

    let hoursStr: string, minutesStr: string, secondsStr: string;
    if (hours < 10) { hoursStr = '0' + hours; } else { hoursStr = hours.toString(); }
    if (minutes < 10) { minutesStr = '0' + minutes; } else { minutesStr = minutes.toString(); }
    if (seconds < 10) { secondsStr = '0' + seconds; } else { secondsStr = seconds.toString(); }
    return hoursStr + ':' + minutesStr + ':' + secondsStr;
  }

  canCharge() {
    // Cam charge only if result is NOT APPROVED o 'Wallet not found' o ERROR-WS
    return ['NOT APPROVED', 'Wallet not found', 'ERROR-WS'].includes(this.transaction.result);
  }

  canReversal() {
    // Can reverse only if result is CAPTURED or ACCEPTED and parentId is null
    return ((this.transaction.result === 'CAPTURED') || (this.transaction.result === 'ACCEPTED')) && (this.transaction.parentId === null)
  }

  async charge() {
    const closeEvent = new EventEmitter<any>();
    const data = new EventEmitter<{
      id: number,
      amount: number,
      reason: string
    }>();
    const popover = await this.popoverController.create({
      component: ChargeReversalFormComponent,
      componentProps: {
        type: 'Charge',
        id: this.transaction.user.id,
        paymentId: this.transaction.paymentid,
        amount: this.transaction.amount,
        closeEvent: closeEvent,
        data: data
      },
      translucent: true,
      cssClass: 'charge-reversal-popover'
    });
    closeEvent.subscribe(() => {
      popover.dismiss();
    });
    data.subscribe((data: { id: number, amount: number, reason: string }) => {
      this.chargeReversalLoading = true;
      const chargeData = {
        ...data,
        userId: data.id,
        parentId: this.transaction.id
      };
      this.wsManager.executeCharge(chargeData).subscribe(() => {
        this.widgetsManager.showToastMessage("success", this.translate.instant("Charge executed successfully."));
        this.chargeReversalLoading = false;
      }, err => {
        console.log("Charge error", err);
        this.widgetsManager.showToastMessage("danger", this.translate.instant("Error executing the charge."));
        this.chargeReversalLoading = false;
      });
    })
    await popover.present();
  }

  async reversal() {
    const closeEvent = new EventEmitter<any>();
    const data = new EventEmitter<{
      id: number,
      amount: number,
      reason: string
    }>();
    const popover = await this.popoverController.create({
      component: ChargeReversalFormComponent,
      componentProps: {
        type: 'Reversal',
        id: this.transaction.id,
        paymentId: this.transaction.paymentid,
        amount: this.transaction.amount,
        closeEvent: closeEvent,
        data: data
      },
      translucent: true,
      cssClass: 'charge-reversal-popover'
    });
    closeEvent.subscribe(() => {
      popover.dismiss();
    });
    data.subscribe((data: { id: number, amount: number, reason: string }) => {
      this.chargeReversalLoading = true;
      const reversalData = {
        ...data,
        transactionId: data.id,
        parentId: this.transaction.id
      };
      this.wsManager.executeReversal(reversalData).subscribe(() => {
        this.widgetsManager.showToastMessage("success", this.translate.instant("Reversal executed successfully."));
        this.chargeReversalLoading = false;
      }, err => {
        console.log("Charge error", err);
        this.widgetsManager.showToastMessage("danger", this.translate.instant("Error executing the reversal."));
        this.chargeReversalLoading = false;
      });
    })
    await popover.present();
  }

}
