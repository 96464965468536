import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Model } from '../classes';
import {SettingsmanagerService} from './settingsmanager.service';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  private _url: string;

  constructor(
    private _http: HttpClient,
    private settingsManager: SettingsmanagerService
  ) {
    this._url =  this.settingsManager.getBaseWsUrl();
  }

  public list() : Observable<Model[]> {
    return this._http.get<Model[]>(`${this._url}/model`).pipe(
      map(obj => obj.map(x => new Model(x)))
    );
  }

  public get(id: number): Observable<Model> {
    return this._http.get<Model>(`${this._url}/model/${id}`).pipe(
      map(obj => new Model(obj))
    );
  }

  public save(obj: Model, file?: File): Observable<Model> {
    return obj.id ? this.edit(obj.id, obj, file) : this.create(obj, file);
  }

  public create(obj: Model, file?: File): Observable<Model> {
    const formData = new FormData();
    if (file) {
      formData.append('streamfile', file, file.name);
    }
    formData.append('data', JSON.stringify(obj));

    return this._http.post<Model>(`${this._url}/model`, formData).pipe(map(obj => new Model(obj)));
  }

  public edit(id: number, obj: Model, file?: File): Observable<Model> {
    const formData = new FormData();
    if (file) {
      formData.append('streamfile', file, file.name);
    }
    formData.append('data', JSON.stringify(obj));

    return this._http.patch<Model>(`${this._url}/model/${id}`, formData).pipe(map(obj => new Model(obj)));
  }

  public delete(id: number): Observable<void> {
    return this._http.delete<void>(`${this._url}/model/${id}`);
  }

  public downloadImage(id: number): Observable<File> {
    return this._http.get<Blob>(`${this._url}/model/${id}/image`, { 
      observe: 'response',
      responseType: 'blob' as 'json'
    }).pipe(
      map(x => { return this._blobToFile(x.body, this._getFileNameFromResp(x)); })
    );
  }

  // convert Blob to File
  private _blobToFile(theBlob: Blob, fileName:string): File {
    const b: any = theBlob;
    b.name = fileName;
    return <File>theBlob;
  }

  // get file name from response
  private _getFileNameFromResp(resp: HttpResponse<any>) {
    let fileName = null;
    const contentDisposition = resp.headers.get('Content-Disposition');
    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = fileNameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
    }
    return fileName;
  }

}
