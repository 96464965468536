import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { PagesRoutingModule } from './pages/pages-routing.module';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

// BS plugins
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';

// BS modules
import { IonicStorageModule } from '@ionic/storage';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SendFcmNotificationPageModule } from './managers/send-fcm-notification/send-fcm-notification.module';
import { ZonedetailPageModule } from './managers/zonedetail/zonedetail.module';
import { CitydetailPageModule } from './managers/citydetail/citydetail.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { WarningPopoverPageModule } from './managers/warning-popover/warning-popover.module';
import { EditDevicePopoverPageModule } from './managers/edit-device-popover/edit-device-popover.module';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { ChangeStatePopoverPageModule } from './managers/change-state-popover/change-state-popover.module';
import { ComponentsModule } from './components/components.module';
import { UpdateService } from './services/update.service';
import { TokenInterceptor } from './classes/guards/token.interceptor';

import { BACKEND_URL } from './app.tokens';
import { CustomLoader } from './utils';
import { RestErrorAuthInterceptor } from './classes/guards/rest-error-auth.interceptor';
import { SettingsmanagerService } from './services/settingsmanager.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    PagesRoutingModule,
    HttpClientModule,
    WarningPopoverPageModule,
    EditDevicePopoverPageModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useClass: CustomLoader}
    }),
    SendFcmNotificationPageModule,
    ChangeStatePopoverPageModule,
    ZonedetailPageModule,
    CitydetailPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ComponentsModule,
    LeafletModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    BarcodeScanner,
    Geolocation,
    InAppBrowser,
    Camera,
    Insomnia,
    File,
    CallNumber,
    CameraPreview,
    SocialSharing,
    UpdateService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: RestErrorAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    private _settSvc: SettingsmanagerService
  ) {
    this._settSvc.initAppSettings();
  }
}
