import { ConvDataDefinition } from './ConvDataDefinition';
import { ConvInvocation } from './ConvInvocation';

export class ConvUserData {
    public id: number;
    public data: string;
    public convInvocation: ConvInvocation;
    public convDataDefinition: ConvDataDefinition;

    constructor(obj?: any) {
        if (!obj) {
            return;
        }
        Object.assign(this, obj);
    }
}