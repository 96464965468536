import { City } from '.';

export enum DeviceStateEnum {
    INVALID = 0,
    IDLE = 1,
    BOOKED = 2,
    RENT = 3,
    SUSPENDED = 4,
    BATTERY_LOW = 5,
    ACTION_REQUIRED = 6,
    CHARGER = 7,
    FAULTY = 8,
    LOST = 9,
    OPERATOR = 10,
    STOKED = 11,
    SEARCHING = 12
}

export enum DeviceType {
    MONOPATTINO = 'MONOP',
    SCOOTER = 'SCOOT',
    CAR = 'CAR',
    BIKE = 'BIKE',
    CHARGER_STATION = 'CHRGST'
}

// export interface IScooterAdditionalInfo {
//     helmet: boolean;
//     easel: boolean;
//     trunk: boolean;
//     status: boolean;
// }

export class DeviceModel {
    id: number;
    name: string;
    description: string;
    active_fee: number;
    break_fee: number;
    unlock_fee: number;
    booking_fee: number;
    photoFile: string;

    constructor(values?: any) {
        if (!values) {
            return;
        }
        Object.assign(this, values);
    }
}

export class DeviceData {
    id: number;
    server_time: Date;
    device_time: Date;
    latitude: number;
    longitude: number;
    speed_position: number;
    satellites: number;
    battery_fuel: number;
    speed_status: number;
    ignition: boolean;
    km_odo: number;
    km_trip: number;
    locked: boolean;
    ext_battery: number;
    door: boolean;
    stand: boolean;
    box: boolean;
    in_charge: boolean;
    on_side: boolean;
    movement: boolean;
    crash: boolean;
    tow: boolean;

    constructor(values?: any) {
        if (!values) {
            return;
        }
        Object.assign(this, values);
        if (values.server_time) {
            this.server_time = new Date(values.server_time);
        }
        if (values.device_time) {
            this.server_time = new Date(values.device_time);
        }
    }
}

export enum ChargeStationStatus {
    STAND_BY = 1,
	WAITING_FOR_CABLE = 2,
	CHARGING = 3,
    ERROR = 4,
	OCCUPIED_NOT_CHARGING = 5
}

export class ChargeStationData {
    id: number;
    deviceId: number;
    device: Device;
    charge_meter_kwh: number;
    instant_kw: number;
    power_config: number;
    status: ChargeStationStatus;
    total_meter_kwh: number;

    constructor(values?: any) {
        if (!values) {
            return;
        }
        Object.assign(this, values);
    }

    public static getLabelStatus(cod: ChargeStationStatus): string {
        switch (cod) {
            case ChargeStationStatus.CHARGING:
                return 'enum.chargeStationStatus.charging';
            case ChargeStationStatus.ERROR:
                return 'enum.chargeStationStatus.error';
            case ChargeStationStatus.OCCUPIED_NOT_CHARGING:
                return 'enum.chargeStationStatus.occupied_not_charging';
            case ChargeStationStatus.STAND_BY:
                return 'enum.chargeStationStatus.stand_by';
            case ChargeStationStatus.WAITING_FOR_CABLE:
                return 'enum.chargeStationStatus.waiting_for_cable';
            default:
                return '';
        }
    }
}

export class Device {
    id: number;
    deviceType: DeviceType;
    name: string;
    imei: number;
    protocol: string;
    createdAt: string;
    modelId: number;
    model: DeviceModel | string;
    ICCID: string;
    fw_version: string;
    hw_version: string;
    blocked: boolean;
    alarms_enabled: boolean;
    attributes: string;
    cityId: number;
    stateId: DeviceStateEnum;
    ext_battery: number;
    battery_fuel: number;
    in_charge: number;
    is_active: number;
    latitude: number;
    longitude: number;
    speed: number;
    satellites: number;
    device_time: string;
    search_string: string;
    city: City;
    stateName: string;
    cityName: string;
    odometer: number;
    batteryCycles: number;
    ping: string;
    tripint: string;
    statint: string;
    impact: string;
    blekey: string;
    netconfig: string;
    lastRental: string;
    enteId: number;
    lastMaintenance: string;

    deviceData: DeviceData;
    chargeStationData: ChargeStationData;

    constructor(values?: any) {
        if (!values) {
            return;
        }
        Object.assign(this, values);
        if (values.model) {
            this.model = typeof values.model === 'string' ? values.model : new DeviceModel(values.model);
        }
        if (values.chargeStationData) {
            this.chargeStationData = new ChargeStationData(values.chargeStationData);
        } else if (values.charge_meter_kwh || values.instant_kw || values.power_config || values.status || values.total_meter_kwh) {
            // per compatibilità formato vecchie chiamate (si usa SQL anzichè ORM) questi valori
            // sono presenti su primo livello anzichè sotto il ramo 'chargeStationData'
            this.chargeStationData = new ChargeStationData();
            this.chargeStationData.charge_meter_kwh = values.charge_meter_kwh;
            this.chargeStationData.instant_kw = values.instant_kw;
            this.chargeStationData.power_config = values.power_config;
            this.chargeStationData.status = values.status;
            this.chargeStationData.total_meter_kwh = values.total_meter_kwh;
        }
        this.search_string = this.id + ' ' + this.imei + ' ' + this.model + ' ' + this.protocol;
    }

    public static getLabelDeviceType(cod: DeviceType): string {
        switch (cod) {
            case DeviceType.BIKE:
                return 'enum.deviceType.bike';
            case DeviceType.CAR:
                return 'enum.deviceType.car';
            case DeviceType.MONOPATTINO:
                return 'enum.deviceType.monop';
            case DeviceType.SCOOTER:
                return 'enum.deviceType.scooter';
            case DeviceType.CHARGER_STATION:
                return 'enum.deviceType.charger_station';
            default:
                return '';
        }
    }

    public static getLabelDeviceState(stateId: DeviceStateEnum): string {
        switch (stateId) {
            case DeviceStateEnum.INVALID:
                return '-';
            case DeviceStateEnum.IDLE:
                return 'enum.deviceState.Idle';
            case DeviceStateEnum.BOOKED:
                return 'enum.deviceState.Booked';
            case DeviceStateEnum.RENT:
                return 'enum.deviceState.On_trip';
            case DeviceStateEnum.SUSPENDED:
                return 'enum.deviceState.Suspended';
            case DeviceStateEnum.BATTERY_LOW:
                return 'enum.deviceState.Battery_low';
            case DeviceStateEnum.ACTION_REQUIRED:
                return 'enum.deviceState.Action_required';
            case DeviceStateEnum.CHARGER:
                return 'enum.deviceState.Charger';
            case DeviceStateEnum.FAULTY:
                return 'enum.deviceState.Faulty';
            case DeviceStateEnum.LOST:
                return 'enum.deviceState.Lost';
            case DeviceStateEnum.OPERATOR:
                return 'enum.deviceState.Operator';
            case DeviceStateEnum.STOKED:
                return 'enum.deviceState.Stoked';
            case DeviceStateEnum.SEARCHING:
                return 'enum.deviceState.Searching';
            default:
                return '';
          }
    }

}
