import { PricingPlan } from './WSClasses';

export class TimeSlot {
    public id: number;
    public startMillis: number;
    public endMillis: number;
    public unlockDiscount: number;
    public activeDiscount: number;
    public breakDiscount: number;
    public pricingPlan: PricingPlan;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
