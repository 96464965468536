import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { WidgetsmanagerService } from './widgetsmanager.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationmanagerService } from './authenticationmanager.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  private promptShowing = false;

  constructor(
    private updates: SwUpdate,
    private translate: TranslateService,
    private widgetsManager: WidgetsmanagerService,
    private authManager: AuthenticationmanagerService
    ) {
      
    this.updates.available.subscribe((event) => {
      if( this.authManager.isUserLogged() ) {
        this.promptUser();
      } else {
        this.updates.activateUpdate().then(() => document.location.reload());
      }
    });

    if (updates.isEnabled) {
      this.updates.checkForUpdate();
      interval( 300 * 1000 ).subscribe(
        () => { 
          this.updates.checkForUpdate();
        });
    }
  }

  private promptUser(): void {
    const message = this.translate.instant('A new update is available, click OK to update right now.');
    const buttons = [
      {
        text: this.translate.instant('Update later'),
        handler: () => {
          this.promptShowing = false;
        }
      }, {
        text: this.translate.instant('OK'),
        handler: () => {
          this.updates.activateUpdate().then(() => document.location.reload());
        }
      }
    ]
    if(!this.promptShowing) {
      this.promptShowing = true;
      this.widgetsManager.showToastMessage('light', message, 0, null, buttons);
    }
  }
}
