import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SeasonTicket, SeasonTicketType } from 'src/app/classes';
import { WidgetsmanagerService } from 'src/app/services/widgetsmanager.service';
import { WsmanagerService } from 'src/app/services/wsmanager.service';

@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.scss'],
})
export class AddSubscriptionComponent implements OnInit {

  @Input('closeEvent')
  public closeEvent: EventEmitter<any>;

  @Output('newSubscription')
  public newSubscription: EventEmitter<SeasonTicket>;

  public addSubscriptionForm: FormGroup;
  public validation_messages = {};
  public ticketTypes: SeasonTicketType[];
  public loading = false;

  constructor(
    private wsManager: WsmanagerService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private widgetManager: WidgetsmanagerService
  ) { }

  ngOnInit() {
    
    this.addSubscriptionForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
      ticketTypeId: ['', Validators.compose([
        Validators.required
      ])]
    });

    this.validation_messages = {
      'email': [
        { type: 'required', message: 'Email is required.' },
        { type: 'pattern', message: 'Please enter a valid email.' }
      ],
      'ticketTypeId': [
        { type: 'required', message: 'Field is required.'},
      ],
    };

    this.loading = true;
    this.wsManager.fetchSeasonTicketTypes().subscribe(items => {
      this.loading = false;
      this.ticketTypes = items;
    }, err => {
      this.loading = false;
      console.log(err);
      this.widgetManager.showToastMessage('danger', this.translate.instant('Error retrieving ticket types'));
    });
  }

  onSubmit(values) {
    this.loading = true;
    
    this.wsManager.addSubscriptionFromAdmin(
        values.email,
        values.ticketTypeId.id
    ).subscribe(data => {
      this.loading = false;
      this.newSubscription.emit(data);
      this.close();
      this.widgetManager.showToastMessage('success', 'Subscription added successfully');
    }, err => {
      this.loading = false;
      if (err.status === 409) { // CONFLICT
        this.widgetManager.showToastMessage('danger', this.translate.instant('A subscription is already active for the specified user'));
      }else{
        this.widgetManager.showToastMessage('danger', this.translate.instant('Error while adding subscription')); 
      }
    });
  }

  public getPacketDescription(obj: SeasonTicketType) {
    return this.translate.instant(SeasonTicketType.getTypeLabel(obj.type)) + ' - ' + obj.name;
  }

  close(){
    if (this.closeEvent) {
      this.closeEvent.emit();
    }
  }
}
