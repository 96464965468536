import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
// import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, Observable, of } from 'rxjs';

// BS service providers
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import SimpleCrypto from 'simple-crypto-js';
import { RoleIdEnum } from '../classes';
import { AppKeyManager } from '../utils/app-key-manager';
import { SettingsmanagerService } from './settingsmanager.service';

const CHARGER_ROLE_ID = 4;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationmanagerService {

  authenticationState = new BehaviorSubject(false);
  tokenKey = '';
  profileKey = '';
  profile = null;

  lastUserStatus: any = null;

  constructor(private plt: Platform,
    private httpClient: HttpClient,
    private router: Router,
    private alertController: AlertController,
    private settingsManager: SettingsmanagerService
  ) {
    console.log('constructor AUTHenticationmanager');
    this.tokenKey = this.settingsManager.getTokenKey();
    this.profileKey = this.settingsManager.getProfileKey();
    this.plt.ready().then(() => {
      // console.log('@@@@@ AuthenticationmanagerService: checkTokenValidity');
      // this.checkTokenValidity();
      this.getUserProfile();
    });
  }

  logout() {
    console.log('@@@@ logout');
    // this.router.navigate(['login']);
    this.setUserNotAuthenticated();
    this.deleteUserProfile();
  }

  setUserAuthenticated(token) {
    console.log('@@@ setUserAuthenticated');
    this.authenticationState.next(true);
    localStorage.setItem(this.tokenKey, token);
  }

  setUserNotAuthenticated() {
    console.log('@@@ setUserNotAuthenticated');
    this.authenticationState.next(false);
    localStorage.removeItem(this.tokenKey);
  }

  setUserProfile(response) {
    this.profile = response;
    localStorage.setItem(this.profileKey, JSON.stringify(response));
  }

  getUserRoleId() {
    if (this.profile) {
      return this.profile.role;
    } else {
      return 0;
    }
  }

  getTermsAndConditionsAccepted() {
    if (this.profile) {
      const terms = this.profile.usage_conditions_accepted ? this.profile.usage_conditions_accepted : false;
      const privacy = this.profile.privacy_conditions_accepted ? this.profile.privacy_conditions_accepted : false;
      const unfair_terms = this.profile.unfair_terms_accepted ? this.profile.unfair_terms_accepted : false;
      return terms && privacy && unfair_terms;
    } else {
      return false;
    }
  }

  setTermsAndConditionsAccepted() {
    this.profile.usage_conditions_accepted = true;
    this.profile.privacy_conditions_accepted = true;
    this.profile.unfair_terms_accepted = true;
    localStorage.removeItem(this.profileKey);
    localStorage.setItem(this.profileKey, JSON.stringify(this.profile));
  }

  upgradeToChargerRole(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Updating the role
      this.profile.role = CHARGER_ROLE_ID;
      localStorage.removeItem(this.profileKey);
      localStorage.setItem(this.profileKey, JSON.stringify(this.profile));
      resolve();
    });
  }

  setUserBlocked() {
    console.log('setUserBlocked');
    this.profile.blocked = true;
    localStorage.removeItem(this.profileKey);
    localStorage.setItem(this.profileKey, JSON.stringify(this.profile));
  }

  setUserUnblocked() {
    console.log('setUserUnblocked');
    this.profile.blocked = false;
    localStorage.removeItem(this.profileKey);
    localStorage.setItem(this.profileKey, JSON.stringify(this.profile));
  }

  isUserBlocked() {
    return this.profile.blocked;
  }

  getLoggedUserRole() {
    return this.settingsManager.getRoleNameById(this.profile.role);
  }

  isUserCustomer() {
    return this.getUserRoleId() === RoleIdEnum.USER;
  }

  isUserCharger() {
    return this.getUserRoleId() === RoleIdEnum.CHARGER;
  }

  isUserCityManager() {
    return this.getUserRoleId() === RoleIdEnum.CITY_MANAGER;
  }

  isUserOperationsManager() {
    return this.getUserRoleId() === RoleIdEnum.OP_MANAGER;
  }

  isUserAdministrator() {
    return this.getUserRoleId() === RoleIdEnum.ADMIN;
  }

  isUserOperator() {
    return this.getUserRoleId() === RoleIdEnum.OPERATOR;
  }

  isUserGuest() {
    return this.getUserRoleId() === RoleIdEnum.GUEST;
  }

  isUserOpendata() {
    return this.getUserRoleId() === RoleIdEnum.OPENDATA;
  }

  isUserInManagersGroup() {
    return (this.getUserRoleId() === RoleIdEnum.ADMIN) ||
      (this.getUserRoleId() === RoleIdEnum.OP_MANAGER) ||
      (this.getUserRoleId() === RoleIdEnum.CITY_MANAGER);
  }

  getLoggedUserName() {
    return this.profile.email;
  }

  getLoggedUserEmail() {
    return this.profile.email;
  }

  getLoggedUserFirstName() {
    return this.profile.firstName;
  }

  getLoggedUserId() {
    return this.profile.id;
  }

  getLoggedUserLastName() {
    return this.profile.lastName;
  }

  getLoggedUserTelephone() {
    return this.profile.telephone;
  }

  getUserProfile() {
    // return JSON.parse(localStorage.getItem(this.profileKey));
    if (!this.profile) {
      const jsonProfile = localStorage.getItem(this.profileKey);
      if (jsonProfile) {
        this.profile = JSON.parse(jsonProfile);
      }
    }
    return this.profile;
  }

  isUserProfileNull() {
    return this.profile === null;
  }

  deleteUserProfile() {
    this.profile = null;
    localStorage.removeItem(this.profileKey);
  }

  isUserLogged() {
    return !!localStorage.getItem(this.tokenKey);
  }

  checkTokenValidity(): Observable<boolean> {
    // Con questo metodo si verifica che un utente sia effettivamente autenticato
    // L'utente è autenticato  se c'è un token e se questo token è valido
    // Getting the token saved in the storage
    const token = localStorage.getItem(this.tokenKey);
    // Checking if the stored token is still valid
    if (token) {
      return this.checkToken(token).pipe(
        map(() => true),
        catchError(() => of(false))
      );
    }

    return of(false);
  }

  /* Web Services */

  login(username, password, coords) {
    console.log('@@@@ LOGIN DATA SENT:', username, password, coords);
    const loginUrl = this.settingsManager.getBaseWsUrl() + '/auth/login';
    const appKey = AppKeyManager.createAppKeyHash(username, password);
    return this.httpClient.post(loginUrl, {
      email: username,
      password: password,
      latitude: coords.latitude,
      longitude: coords.longitude,
      appKey
    });
  }

  register(registrationData) {
    console.log('@@@@ register:', registrationData);
    const registrationUrl = this.settingsManager.getBaseWsUrl() + '/user';
    return this.httpClient.post(registrationUrl, registrationData);
  }

  isTester(token) {
    const commandUrl = this.settingsManager.getBaseWsUrl() + '/auth/tester';
    const reqHeader = this.getHeader(token);
    console.log('@@@@ isTester', commandUrl);
    const responseType = 'json';
    return this.httpClient.get(commandUrl,
      { headers: reqHeader, responseType: responseType });
  }

  getHeader(token) {
    const reqHeader = {
      'Content-Type': 'application/json',
      'Accept': 'plain/text',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
      'Access-Control-Allow-Headers': 'Authorization, Origin, Content-Type, X-CSRF-Token',
      'Cache-control': 'no-cache',
      'Expires': '0',
      'Pragma': 'no-cache',
      'auth': token
    };
    return reqHeader;
  }

  /**
   * Verifica la validità del token
   * Può essere invocato con o senza coordinate:
   *  - Senza coordinate per verificare solo se l'utente è loggato (token valido)
   *  - Con le coordinate per effettuare verifiche ulteriori sull'utente
   *
   */
  checkToken(token: string, coords = null) {
    const l_currentAppVersion = this.settingsManager.getAppVersion();
    const registrationUrl = this.settingsManager.getBaseWsUrl() + '/auth/check-token';
    const reqHeader = this.getHeader(token);

    const data = {
      currentAppVersion: l_currentAppVersion,
      latitude: 0,
      longitude: 0
    };
    if (coords) {
      data.latitude = coords.latitude;
      data.longitude = coords.longitude;
    }
    return this.httpClient.post(
      registrationUrl,
      data,
      { headers: reqHeader, responseType: 'text' });
  }


  /* RECOVER PASSWORD */

  /**
   * Phase1: the user writes the email/sms address used in the registration phase
   *  The WS sends to the user an OTP code that must be used to change the pwd
   *  in the Phase2.
   *
   * @param userEmail: the email of the user.
   */
  recoverPasswordByEmail(userEmail: string) {
    console.log('@@@recoverPasswordByEmail');
    const wsData = {
      email: userEmail
    };
    const wsUrl = this.settingsManager.getBaseWsUrl() + '/auth/restore-password-1';
    return this.httpClient.post(wsUrl, wsData);
  }

  /**
   * Phase2: the users writes the the OTP code received by email/sms
   * with the new password. The WS checks the OTP and if correct changes the password.
   *
   * @param newPassword: The new password.
   * @param otpString: The OTP code received by email.
   */
  changePasswordByOtp(newPassword: string, otpString: string) {
    console.log('@@@recoverPasswordByEmail');
    const wsData = {
      newPassword: newPassword,
      otp: otpString,
    };
    const wsUrl = this.settingsManager.getBaseWsUrl() + '/auth/restore-password-2';
    return this.httpClient.post(wsUrl, wsData);
  }

  // Send email confirmation message to user
  sendConfirmationEmail(userEmail: string) {
    console.log('@@@sendConfirmationEmail');
    const wsData = {
      email: userEmail
    };
    const wsUrl = this.settingsManager.getBaseWsUrl() + '/auth/send-confirmation-mail';
    return this.httpClient.post(wsUrl, wsData);
  }

  sendSms(userEmail: string, telephone: string) {
    const wsData = {
      email: userEmail,
      telephone: telephone
    };
    const wsUrl = this.settingsManager.getBaseWsUrl() + '/sms/send-again';
    return this.httpClient.post(wsUrl, wsData);
  }

  // Contro la mia volonta' faccio questa modifica per salvare username e password in chiaro
  saveUserCredentials(username, password) {
    const userdata = {
      'u': this.encryptString(username),
      'p': this.encryptString(password),
    };
    localStorage.setItem('up', JSON.stringify(userdata));
    console.log('saveUserCredentials: ', userdata);
  }

  getUserCredentials() {
    const jsonCred = localStorage.getItem('up');
    if (jsonCred) {
      const cred = JSON.parse(jsonCred);
      cred.u = this.decryptString(cred.u);
      cred.p = this.decryptString(cred.p);
      return cred;
    } else {
      return null;
    }
  }

  encryptString(textString, key = 'geostrack') {
    const simpleCrypto = new SimpleCrypto(key);
    return simpleCrypto.encrypt(textString);
  }

  decryptString(textString, key = 'geostrack') {
    const simpleCrypto = new SimpleCrypto(key);
    return simpleCrypto.decrypt(textString);
  }

  isLoggedUserDeveloper() {
    return this.settingsManager.isLoggedUserDeveloper(this.getLoggedUserEmail());
  }

  checkOtp(otpString: string) {
    console.log('@@@checkOtp', otpString);
    const wsUrl = this.settingsManager.getBaseWsUrl() + '/otp/sms/' + otpString;
    return this.httpClient.get(wsUrl);
  }

  getLastUserStatus() {
    return this.lastUserStatus;
  }

  setLastUserStatus(status) {
    this.lastUserStatus = status;
  }

  resetLastUserStatus() {
    this.lastUserStatus = null;
  }

  /*
  * Update the Profile object on the service and in the local storage
  */
  updateUserProfile(userData) {
    // @TODO: Ricaricare il profilo dai webservice usando setUserProfile
    console.log('@@@userdta', userData);
    if (userData && userData.firstName) {
      this.profile.firstName = userData.firstName;
    }
    if (userData && userData.lastName) {
      this.profile.lastName = userData.lastName;
    }
    localStorage.removeItem(this.profileKey);
    localStorage.setItem(this.profileKey, JSON.stringify(this.profile));
  }

}
