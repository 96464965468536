import { Injectable } from '@angular/core';
import {NavController, Platform, PopoverController, ToastController} from '@ionic/angular';

// BS modules
import { BehaviorSubject } from 'rxjs';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { Coordinates } from './../classes';

// BS service providers
import { WsmanagerService } from './wsmanager.service';
import { SettingsmanagerService } from './settingsmanager.service';
import { AuthenticationmanagerService } from './authenticationmanager.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { RentalService } from './rental.service';

const CURRENT_BUILD_READ = 'last_build_read';

@Injectable({
  providedIn: 'root'
})
export class RentalmanagerService {

  device_status = null;

  /* Rental data */

  public forceViewVehicleDashboard: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public rentedVehicleId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public rentedVehicleName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public bookedVehicleId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public bookedVehicleName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public bookedVehicleRentable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userRentingVehicle: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  // Dati provenienti dallo stato del veicolo (dati presi dallo scooter)
  public deviceStatusId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public deviceBatteryLevel: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public deviceSpeed: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public deviceLatitude: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public deviceLongitude: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public deviceAltitude: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public deviceDataTimestamp: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  // Dati provenienti da Geolocation (dal cellulare)
  public mobileLatitude: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public mobileLongitude: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public mobileAltitude: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public mobileHeading: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public mobileSpeed: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public mobileDataTimestamp: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private followUsersubscription = null;
  private termsPopoverShown = false;

  // Dati riguardanti la posizione e il limite di velocità
  public currentCityId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public currentAreaType: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public currentZoneId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public currentAreaName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public currentAreaDescription: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public currentAreaColor: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public currentSpeedId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public currentParkingEnabled: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public currentFeeUnlockEnabled: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private lastZoneId = null;

  /* Rental timers */
  public timerIdStopWatch: any = null;
  public timerIdStatus: any = null;
  public timerSendDataToServer: any = null;

  /* Rental StopWatch data */
  public cron_hours: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public cron_min: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public cron_sec: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  /* Booking StopWatch data */
  public booking_cron_hours: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public booking_cron_min: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public booking_cron_sec: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public maxBookingTime = 0;

  /* Rental timers */
  public bookingTimerIdStopWatch: any = null;
  public bookingTimerIdCheckRentable: any = null;
  public bookingTimerIdStatus: any = null;



  constructor(
    private settingsManager: SettingsmanagerService,
    private _rentalSvc: RentalService,
    private wsManager: WsmanagerService,
    private platform: Platform,
    private toastController: ToastController,
    private geolocation: Geolocation
  ) {
    console.log('@@@ constructor RentalmanagerService');

    // Riapro cruscotto se riapro l'app ed ho un noleggio attivo
    // La redirect è fatta nel metodo customer-page-->ionViewDidEnter
    // perché qui è troppo presto.
    this.platform.ready().then(() => {
      console.log('*** platform.READY RENTAL ***');
        this.forceViewVehicleDashboard.next(1);
    });

  }

  /**
   *  Calls the web service to get the status of deviceId
   *  and updates the status of the vehicle on the provider.
   *
   * @param deviceId: the id of the device.
   */
  getDeviceStatus(deviceId: number, pageObject= null): Promise<any> {
    console.log('@@@@@@@@@ getDeviceStatus @@@@@@@@@', deviceId);
    let context;
    if (pageObject) {
      context = pageObject;
    } else {
      context = this;
    }
    return new Promise ((resolve, reject) => {
      context.wsManager.fetchDeviceData(deviceId).subscribe(data => {
        const tmstp = Number(new Date().getTime());
        console.log('@@@getDeviceStatus-data:', data, tmstp);
        context.device_status = data;
        context.deviceStatusId.next(context.device_status.stateId);
        context.deviceBatteryLevel.next(context.device_status.battery);
        context.deviceLatitude.next(context.device_status.latitude);
        context.deviceLongitude.next(context.device_status.longitude);
        context.deviceAltitude.next(context.device_status.altitude);
        context.deviceDataTimestamp.next(tmstp);
        context.userRentingVehicle.next(context.device_status.userId);

        resolve(context.device_status);
      }, err => {
        console.log('ERR-POSITIONS6:', err.message);
        reject(err.message);
      });
    });
  }

  /**
   *  Calls the web service to start the rental of deviceId
   *
   * @param deviceId: the id of the device.
   */
  async startRental(startRentalData): Promise<any> {
    const deviceId =  startRentalData.deviceId;

    return new Promise ((resolve, reject) => {
      this.wsManager.startRental(startRentalData)
        .subscribe(data => {
          // console.log('@@@startRental-data:', data);
          // I change the status of the device
          // @TODO: should I call again getDeviceStatus ?
          const newStatusId = this.settingsManager.getDeviceStatusIdByName('on_trip');
          // necessario per evitare che al primo rientro sulla mappa ci sia la redirezione sul cruscotto
          this.forceViewVehicleDashboard.next(0);
          this.changeDeviceStatus(newStatusId);
          this.changeRentalStatus(deviceId, startRentalData.deviceName, 'start');
          this.startWatch();
          this.closeBookingIfPresent();
          resolve(this.device_status);
        }, err => {
          console.log('ERR-START:', err.message);
          reject(err);
        });
    });
  }

  checkCurrentPosition (coordinates): Promise<any> {
    // return this.wsManager.checkCurrentPosition(coordinates).toPromise();
    return new Promise ((resolve, reject) => {
      this.wsManager.checkCurrentPosition(coordinates)
        .subscribe(data => {
          resolve(data);
        }, err => {
          console.log('ERR-START:', err.message);
          reject(err);
        });
    });
  }


  /**
   *  Calls the web service to close the rental of deviceId
   *
   * @param deviceId: the id of the device.
   */
  closeRental(deviceId: number, coordinates: any, forcePark): Promise<any> {
    return new Promise ((resolve, reject) => {
      this._rentalSvc.closeRental(forcePark)
        .subscribe(data => {
          console.log('data:', data);
          const newStatusId = this.settingsManager.getDeviceStatusIdByName('idle');
          const rental_status = data;
          this.changeRentalStatus(deviceId, this.rentedVehicleName.getValue(), 'close');
          this.changeDeviceStatus(newStatusId);
          this.stopWatch();
          resolve(rental_status);
      }, err => {
          console.log('ERR-CLOSE:', err.message);
          reject(err);
      });
    });
  }

  async sendRentalPhoto (rentalId: number, fileObject: any): Promise<any> {
    return new Promise ( (resolve, reject) => {
      this.wsManager.sendRentalPhoto(rentalId, fileObject)
          .subscribe( (data) =>  {
            resolve(data);
          }, err => {
            console.log('ERR-START:', err.message);
            reject(err);
          });
    });
  }

  /**
   *  Calls the web service to start a pause during the rental of deviceId
   *
   * @param deviceId: the id of the device.
   */
  startBreakRental(deviceId: number): Promise<any> {
    return new Promise ((resolve, reject) => {
      this.wsManager.startBreakRental(deviceId)
        .subscribe(data => {
          console.log('@@@startBreak-data:', data);
          // I change the status of the device
          // @TODO: should I call again getDeviceStatus ?
          const newStatusId = this.settingsManager.getDeviceStatusIdByName('suspended');
          this.changeDeviceStatus(newStatusId);
          resolve(this.device_status);
      }, err => {
          console.log('ERR-START-BREAK:', err.message);
          reject(err.message);
      });
    });
  }

  /**
   *  Calls the web service to stop a pause during the rental of deviceId
   *
   * @param deviceId: the id of the device.
   */
  stopBreakRental(deviceId: number): Promise<any> {
    return new Promise ((resolve, reject) => {
      this.wsManager.stopBreakRental(deviceId).subscribe(data => {
        console.log('@@@stopBreak-data:', data);
        // I change the status of the device
        // @TODO: should I call again getDeviceStatus ?
        const newStatusId = this.settingsManager.getDeviceStatusIdByName('on_trip');
        this.changeDeviceStatus(newStatusId);
        resolve(this.device_status);
      }, err => {
        console.log('ERR-STOP-BREAK:', err.message);
        reject(err.message);
      });
    });
  }

  /**
   * Changes the status of the device in the structures of the service provider
   * without calling getDeviceStatus and the web service and assuming that the
   * device has really changed its status.
   *
   * @param newStatusId: the status of the device.
   */
  changeDeviceStatus(newStatusId: number): void {
    this.device_status.stateId = newStatusId;
    this.deviceStatusId.next(newStatusId);
  }

  changeRentalStatus(deviceId: number, deviceName: string, action: string): void {
    const rentedVehicleKey = this.settingsManager.getRentedVehicleKey();
    switch (action) {
      case 'start': {
        this.rentedVehicleId.next(deviceId);
        this.rentedVehicleName.next(deviceName);
        localStorage.setItem(rentedVehicleKey, String(deviceId));
        break;
        }
      case 'close': {
        this.rentedVehicleId.next(null);
        this.rentedVehicleName.next(null);
        this.stopWatch();
        localStorage.removeItem(rentedVehicleKey);
        break;
      }
      case 'reset': {
          this.rentedVehicleId.next(null);
          this.rentedVehicleName.next(null);
          localStorage.removeItem(rentedVehicleKey);
          break;
      }
    }
  }

  /***  Rental StopWatch Management ***/

  startWatch() {
    // We have to create a link to the current object, because the scope
    // of the incrementTimer is different
    // Ref: https://stackoverflow.com/questions/11714397/settimeout-scope-issue
    console.log('******** START RENTAL WATCH ********');

    const pageObject = this;
    // Set the timer to update the Stop Watch
    clearInterval(this.timerIdStopWatch);
    this.timerIdStopWatch = setInterval(this.incrementTimer, 1000, pageObject);

    // Set the timer to update the vehicle status
    const timeInterval = this.settingsManager.getVehicleStatusReloadInterval();
    clearInterval(this.timerIdStatus);
    this.timerIdStatus = setInterval(this.getDeviceStatus, timeInterval, this.rentedVehicleId.getValue(), pageObject);

    // Set the timer to send GeolocationData to the server
    const timeSendDataToServerInterval = this.settingsManager.getSendGeolocationToServerInterval();
    this.sendGeolocationDataToServer(this.rentedVehicleId.getValue(), pageObject);
    clearInterval(this.timerSendDataToServer);
    this.timerSendDataToServer = setInterval(this.sendGeolocationDataToServer, timeSendDataToServerInterval,
      this.rentedVehicleId.getValue(), pageObject);

  }

  stopWatch(): void {
    clearInterval(this.timerIdStopWatch);
    clearInterval(this.timerIdStatus);
    clearInterval(this.timerSendDataToServer );
    this.cron_sec.next(0);
    this.cron_min.next(0);
    this.cron_hours.next(0);
  }

  incrementTimer(pageObject) {
    // console.log('INCREMENT');
    if (pageObject.cron_sec.getValue() + 1 === 60) {
      pageObject.cron_sec.next(0);
      if (pageObject.cron_min.getValue() + 1 === 60) {
        pageObject.cron_min.next(0);
        pageObject.cron_hours.next(pageObject.cron_min.getValue() + 1);
      } else {
        pageObject.cron_min.next(pageObject.cron_min.getValue() + 1);
      }
    } else {
      pageObject.cron_sec.next(pageObject.cron_sec.getValue() + 1);
    }
  }


  /***  Booking StopWatch Management ***/

  closeBookingIfPresent() {
    // console.log('@@@closeBookingIfPresent@@@');
    this.stopBookingWatch();
    this.bookedVehicleName.next(null);
    this.bookedVehicleId.next(null);
    this.bookedVehicleRentable.next(false);
  }

  startBookingWatch() {
    // console.log('******** START BOOKING WATCH ********');
    const pageObject = this;
    // Set the timer to update the Stop Watch
    clearInterval(this.bookingTimerIdStopWatch);
    this.bookingTimerIdStopWatch = setInterval(pageObject.incrementBookingTimer, 1000, pageObject,
      pageObject.settingsManager.getMaxBookingDuration());

    /* Verifico se il veicolo prenotato è noleggiabile in base alla distanza
    *  Una verifica si fa subito le altre a distanza di 20 secondi.
    */
    this.checkBookedVehicleRentable(this, this.settingsManager.getDistanceThreshold());
    clearInterval(this.bookingTimerIdCheckRentable);
    this.bookingTimerIdCheckRentable = setInterval(pageObject.checkBookedVehicleRentable, 20000, pageObject,
      pageObject.settingsManager.getDistanceThreshold());
  }

  stopBookingWatch(): void {
    clearInterval(this.bookingTimerIdStopWatch);
    clearInterval(this.bookingTimerIdStatus);
    clearInterval(this.bookingTimerIdCheckRentable);
    this.booking_cron_sec.next(0);
    this.booking_cron_min.next(0);
    this.booking_cron_hours.next(0);
  }

  incrementBookingTimer(pageObject, maxValue) {
    if (pageObject.booking_cron_sec.getValue() + 1 === 60) {
      pageObject.booking_cron_sec.next(0);
      if (pageObject.booking_cron_min.getValue() + 1 === 60) {
        pageObject.booking_cron_min.next(0);
        pageObject.booking_cron_hours.next(pageObject.booking_cron_hours.getValue() + 1);
      } else {
        pageObject.booking_cron_min.next(pageObject.booking_cron_min.getValue() + 1);
      }
    } else {
      pageObject.booking_cron_sec.next(pageObject.booking_cron_sec.getValue() + 1);
      if (pageObject.booking_cron_min.getValue() >= maxValue) {
        pageObject.closeBookingIfPresent();
      }
    }
  }

  /*
  * Verifies that the booked vehicle is rentable
  * checking the distance between the mobile and the vehicle
   */
  checkBookedVehicleRentable(pageObject, maxDistance) {
    console.log('@@@ checkBookedVehicleRentable maxDistance: @@@', maxDistance);
    pageObject.wsManager.fetchDeviceData(pageObject.bookedVehicleId.getValue()).subscribe(data => {
      const vehicleLat = data.latitude;
      const vehicleLong = data.longitude;
      const dist = pageObject.settingsManager.computeCoordinatesDistance(
        pageObject.mobileLatitude.getValue(),
        pageObject.mobileLongitude.getValue(),
        vehicleLat,
        vehicleLong,
        'T');
      console.log('@@@ checkBookedVehicleRentable distance: @@@', dist,
        pageObject.mobileLatitude.getValue(),
        pageObject.mobileLongitude.getValue(),
        vehicleLat,
        vehicleLong);
      if (dist > pageObject.settingsManager.getDistanceThreshold()) {
        pageObject.bookedVehicleRentable.next(false);
        console.log('#################################### VEICOLO PRENOTATO TROPPO LONTANO');
      } else {
        pageObject.bookedVehicleRentable.next(true);
        console.log('#################################### VEICOLO PRENOTATO DISTANZA OK');
      }
    }, err => {
      console.log('ERR-fetchDeviceData:', err.message);
    });
  }


  /*
  *  Recovers the data of an active rental
  */
  recoverRentalIfNeeded(storedVehicleId, storedVehicleName):  void {
    console.log('########## recoverRentalIfNeeded ########## ');
    const localVehicleId = this.rentedVehicleId.getValue();

    // Check if there is a rental that must be restored
    if ((localVehicleId === null) && (storedVehicleId !== null) ) {
      console.log('########## OK START RECOVER ########## ');
      // retrieves data from the server
      this.wsManager.getRentalStatus()
        .subscribe(data => {
          console.log('DATA-recoverRentalIfNeeded:', data);

          // Adjusting the stop watch
          const startRentalTime = Date.parse(data.start_time);
          const milliseconds = new Date().getTime() - startRentalTime;
          let hours = 0, minutes = 0, seconds = 0;
          seconds = Math.floor(milliseconds / 1000);
          minutes = Math.floor(seconds / 60);
          seconds = seconds % 60;
          hours = Math.floor(minutes / 60);
          minutes = minutes % 60;
          console.log('****** RIPRISTINO CRONOMETRO NOLEGGIO *********');
          console.log('milliseconds: ', milliseconds);
          console.log('hours: ', hours);
          console.log('minutes: ', minutes);
          console.log('seconds: ', seconds);
          console.log('**************************************');
          this.cron_hours.next(hours);
          this.cron_min.next(minutes);
          this.cron_sec.next(seconds);
          this.rentedVehicleId.next(storedVehicleId);
          this.rentedVehicleName.next(storedVehicleName);

          // Faccio ripartire la sincronizzazione dell'orologio
          this.startWatch();

        }, err => {
          console.log('ERR-checkActiveRental:', err);
          this.changeRentalStatus(storedVehicleId, storedVehicleName, 'reset');
        });
    } else {
      console.log('########## NO RENTAL RECOVER NEEDED ########## ');
      this.rentedVehicleId.next(null);
      this.rentedVehicleName.next(null);
    }
  }

  recoverBookingIfNeeded(storedVehicleId, storedVehicleName):  void {
    console.log('########## recoverBookingIfNeeded ########## ');
    const localVehicleId = this.rentedVehicleId.getValue();

    // Check if there is a rental that must be restored
    if ((localVehicleId === null) && (storedVehicleId !== null) ) {
      console.log('########## OK START RECOVER BOOKING ########## ');
      // retrieves data from the server
      this.wsManager.getBookingStatus(storedVehicleId)
        .subscribe(data => {
          console.log('@@@@ DATA-recoverBookingIfNeeded:', data);

          // Adjusting the stop watch
          const startBokingTime = Date.parse(data.booking.start_time);
          const milliseconds = new Date().getTime() - startBokingTime;
          let hours = 0, minutes = 0, seconds = 0;
          seconds = Math.floor(milliseconds / 1000);
          minutes = Math.floor(seconds / 60);
          seconds = seconds % 60;
          hours = Math.floor(minutes / 60);
          minutes = minutes % 60;
          console.log('****** RIPRISTINO CRONOMETRO PRENOTAZIONE *********');
          console.log('milliseconds: ', milliseconds);
          console.log('hours: ', hours);
          console.log('minutes: ', minutes);
          console.log('seconds: ', seconds);
          console.log('**************************************');
          this.booking_cron_hours.next(hours);
          this.booking_cron_min.next(minutes);
          this.booking_cron_sec.next(seconds);
          this.bookedVehicleId.next(storedVehicleId);
          this.bookedVehicleName.next(storedVehicleName);

          // Faccio ripartire la sincronizzazione dell'orologio
          this.startBookingWatch();
          // Aggiorno batteria del veicolo
          this.deviceBatteryLevel.next(data.device.battery_fuel);
        }, err => {
          console.log('ERR-checkActiveBooking:', err);
          // this.changeRentalStatus(storedVehicleId, storedVehicleName, 'reset');
        });
    } else {
      console.log('########## NO BOOKING RECOVER NEEDED ########## ');
      this.bookedVehicleId.next(null);
      this.bookedVehicleName.next(null);
      this.bookedVehicleRentable.next(false);
    }
  }

  //  async sendGeolocationDataToServer(deviceId: number, pageObject= null): Promise<any> {
  async sendGeolocationDataToServer(deviceId: number, pageObject= null) {
    console.log('@@@@@@@@@ sendGeolocationDataToServer @@@@@@@@@', deviceId);
    let context;
    if (pageObject) {
      context = pageObject;
    } else {
      context = this;
    }

    // Recupero i dati presi dal BackgroundGeolocation
    const latitude = context.mobileLatitude.getValue();
    const longitude = context.mobileLongitude.getValue();
    const altitude = context.mobileAltitude.getValue();
    const speed = context.mobileSpeed.getValue() ? context.mobileSpeed.getValue() : 0;

    // Invio i dati solo se latitudine e longitudine hanno senso
    if ((latitude) && (longitude)) {
      // return new Promise ((resolve, reject) => {
        const geodata = {
          'latitude': latitude,
          'longitude': longitude,
          'speed': speed,
          'altitude': altitude,
          'satellites': 0,
          'deviceId': deviceId,
          'sig_quality': 0,
          'sourceId': 2 // source = app
        };

        context.wsManager.sendGeodataToServer(geodata)
          .subscribe(data => {
            // console.log('@@@@@@@@@sendGeodataToServer', data);
            const dataObject = JSON.parse(data);
            // Sei in una zona
            if ((dataObject.city.id) && (dataObject.id)) {
              context.currentCityId.next(dataObject.city.id);
              context.currentAreaName.next(dataObject.name);
              context.currentAreaDescription.next(dataObject.description);
              context.currentAreaColor.next(dataObject.color);
              context.currentSpeedId.next(dataObject.commandTypeId);
              context.currentParkingEnabled.next(dataObject.park_enabled ? 1 : 0);
              context.currentFeeUnlockEnabled.next(dataObject.free_unlock ? 1 : 0);
              // @SPEEDCITYFIX
              context.currentAreaType.next(context.settingsManager.getTypeAreaZone());
              context.currentZoneId.next(dataObject.id);
            }

            // Non sei in una zona
            if ((dataObject.city.id) && (!dataObject.id)) {
              context.currentCityId.next(dataObject.city.id);
              context.currentAreaName.next(dataObject.city.name);
              context.currentAreaDescription.next(dataObject.city.description);
              context.currentAreaColor.next(dataObject.city.color);
              context.currentSpeedId.next(dataObject.city.commandTypeId);
              context.currentParkingEnabled.next(dataObject.city.park_enabled ? 1 : 0);
              context.currentFeeUnlockEnabled.next(dataObject.city.free_unlock ? 1 : 0);
              // @SPEEDCITYFIX
              context.currentAreaType.next(context.settingsManager.getTypeAreaCity());
              context.currentZoneId.next(dataObject.city.id);
            }

            // Fuori città
            if (dataObject.city.id === 0 ) {
              context.currentCityId.next(0);
              context.currentAreaName.next(context.settingsManager.getOutCityAreaName());
              context.currentAreaDescription.next(context.settingsManager.getOutCityAreaDescription());
              context.currentAreaColor.next('D90404');
              context.currentSpeedId.next(context.settingsManager.getSpeedOutCity());
              context.currentParkingEnabled.next(0);
              // @SPEEDCITYFIX
              context.currentAreaType.next(context.settingsManager.getTypeAreaZoneOutCity());
              context.currentZoneId.next(0);
            }
            // resolve();
        }, err => {
          console.log('ERR-POSITIONS7:', err.message);
            if (err.status === 401) {
              const actiondMsg = context.translate.instant('You are no longer authenticated.');
              context.showMessage('danger', actiondMsg, 6000);
              context.authService.logout();
            }
          // reject(err.message);
        });
      // });
    } else {
      console.log('## ERRORE: Lat and/or Long null, or server data.', latitude, longitude);
      // @TODO: Return a promise?
    }
  }


  async showMessage(messageSeverity: string, messageText: string, messageDuration: number = 3000) {
    const toast = await this.toastController.create({
      message: messageText,
      translucent: true,
      duration: messageDuration,
      position: 'bottom',
      mode: 'md',
      cssClass: messageSeverity
    });
    toast.present();
  }


  async startFollowUserPosition() {
    console.log('@@@@@@@ rentalmanager-startFollowUserPosition1');
    if (this.followUsersubscription === null) {
      console.log('startFollowUserPosition2');

      const geo_options = this.settingsManager.getDefaultGeolocationOptions();

      // Prendo la prima posizione per posizionare la mappa
      await this.geolocation.getCurrentPosition(geo_options).then((position) => {
        // console.log('startFollowUserPosition3', position);
        if ((position.coords) && (position.coords.latitude) && (position.coords.longitude)) {
          this.mobileLatitude.next(position.coords.latitude);
          this.mobileLongitude.next(position.coords.longitude);
          this.mobileAltitude.next(position.coords.altitude);
          this.mobileHeading.next(position.coords.heading);
          this.mobileSpeed.next(position.coords.speed);
          const tmstp = Number(new Date().getTime());
          this.mobileDataTimestamp.next(tmstp);
        }
      }).catch((error) => {
        console.log('Error getting location', error);
      });

      console.log('@@@@@@@ rentalmanager-startFollowUserPosition2', this.mobileLatitude.getValue());

      // Seguo la posizione dell'utente
      this.followUsersubscription = this.geolocation.watchPosition(geo_options)
        .subscribe((position: Geoposition) => {
          // console.log('watchPosition', position);
          if ((position.coords) && (position.coords.latitude) && (position.coords.longitude)) {
            this.mobileLatitude.next(position.coords.latitude);
            this.mobileLongitude.next(position.coords.longitude);
            this.mobileAltitude.next(position.coords.altitude);
            this.mobileHeading.next(position.coords.heading);
            this.mobileSpeed.next(position.coords.speed);
            const tmstp = Number(new Date().getTime());
            this.mobileDataTimestamp.next(tmstp);
          }
        }, err => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@@@ERR-watchPosition:', err);
        });

      console.log('--->followUsersubscription: ', this.followUsersubscription);
    }
  }

  stopFollowUserPosition() {
    if (this.followUsersubscription !== null) {
      this.followUsersubscription.unsubscribe();
      this.followUsersubscription = null;
    }
  }

  restartFollowUserPosition() {
    this.stopFollowUserPosition();
    this.startFollowUserPosition();
  }

  getUserPositionCoordinates(): Coordinates {
    const coordinates: Coordinates = new Coordinates();
    coordinates.latitude = this.mobileLatitude.getValue();
    coordinates.longitude = this.mobileLongitude.getValue();
    return coordinates;
  }

  async checkLoggedUserStatus($event) {
    console.log('@@@ checkLoggedUserStatus0');
  }

  getTermsPopoverShown() {
    return this.termsPopoverShown;
  }

  setTermsPopoverShown() {
    this.termsPopoverShown = true;
  }

  resetTermsPopoverShown() {
    this.termsPopoverShown = false;
  }

  private popupAlreadyRead(dataObject: any) {

    const appBuild = Number(this.settingsManager.getAppBuild());
    const lastBuildRead = localStorage.getItem(CURRENT_BUILD_READ) ? (Number(localStorage.getItem(CURRENT_BUILD_READ))) : 0;
    const suggestedBuild = dataObject.currentVersion ? Number(dataObject.currentVersion) : 0;

    if (appBuild < suggestedBuild) {
      if (lastBuildRead < suggestedBuild) {
        localStorage.setItem(CURRENT_BUILD_READ, String(suggestedBuild));
        return false;
      }
    }
    return true;
  }


}

