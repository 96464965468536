import { MethodType } from './MethodType';

export enum BrokerEnum {
    Mercury = 'Mercury',
    Nexi = 'Nexi',
    Stripe = 'Stripe'
}

export class PaymentMethod {
    id: number;
    card_number: string;
    owner_firstName: string;
    owner_lastName: string;
    registration_date: string;
    expiryMonth: number;
    expiryYear: number;
    isValid: boolean;
    userId: number;
    paymentTypeId: number;
    walletId: string;
    paymentType: MethodType;
    broker: BrokerEnum;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
