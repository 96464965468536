import { Component, OnInit } from '@angular/core';
import {Device} from '../../classes';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NavParams, PopoverController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {SettingsmanagerService} from '../../services/settingsmanager.service';
import {WsmanagerService} from '../../services/wsmanager.service';
import {WidgetsmanagerService} from '../../services/widgetsmanager.service'

@Component({
  selector: 'app-edit-device-popover',
  templateUrl: './edit-device-popover.page.html',
  styleUrls: ['./edit-device-popover.page.scss'],
})
export class EditDevicePopoverPage implements OnInit {

  public isSpinnerVisible  = false;

  public device: Device = null;
  public deviceForm: FormGroup;
  public validation_messages = null;
  public cities = null;
  public states = null;

  constructor(
    private navParams: NavParams,
    private translate: TranslateService,
    private settingsManager: SettingsmanagerService,
    private formBuilder: FormBuilder,
    private wsManager: WsmanagerService,
    private widgetManager: WidgetsmanagerService,
    private popoverController: PopoverController
  ) {
    console.log('@contructor-EDIT DEVICE');
  }

  ngOnInit() {
    console.log('@ngOnInit - EDIT DEVICE');
    // Retrieving page parameters
    this.device = this.navParams.get('device');

    this.cities = this.settingsManager.getCitiesOptions().sort(
      (a: any, b: any) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
      }
    );
    this.states = this.settingsManager.getStatesOptions();
    console.log('--->cities', this.cities);
    console.log('--->states', this.states);

    this.deviceForm = this.formBuilder.group({
          id: [this.device.id, Validators.compose([
            Validators.required,
            Validators.pattern('^[0-9]*$')
          ])],
          name: [this.device.name, Validators.compose([
            Validators.required
          ])],
          imei: [this.device.imei, Validators.compose([
            Validators.required,
            Validators.minLength(15),
            Validators.maxLength(15),
            Validators.pattern('^[0-9]*$')
          ])],
          protocol: [this.device.protocol, Validators.compose([
            Validators.required
          ])],
          model: [this.device.model, Validators.compose([
          ])],
          cityId: [this.device.cityId, Validators.compose([
            Validators.required
          ])],
          stateId: [this.device.stateId, Validators.compose([
            Validators.required
          ])],
          blocked: [this.device.blocked, Validators.compose([
          ])]
        }
    );

    this.validation_messages = {
      'id': [
        { type: 'required', message: 'Field is required.'}
      ],
      'name': [
        { type: 'required', message: 'Field is required.'},
      ],
      'imei': [
        { type: 'required', message: 'Field is required.'},
        { type: 'minlength', message: 'Imei length must be equal to 15.' },
        { type: 'maxlength', message: 'Imei length must be equal to 15.' },
        { type: 'pattern', message: 'Only numbers are accepted.' },
      ],
      'protocol': [
        { type: 'required', message: 'Field is required.'},
      ],
      'model': [
      ],
      'cityId': [
        { type: 'required', message: 'Field is required.'},
      ],
      'stateId': [
        { type: 'required', message: 'Field is required.'},
      ],
      'iccid': [
      ],
    };

  }

  showSpinner() {
    this.isSpinnerVisible = true;
  }

  hideSpinner() {
    this.isSpinnerVisible = false;
  }

  async closePopover(reloadParent = false) {
    await this.popoverController.dismiss(reloadParent);
  }

  async onSubmit(values) {
    this.showSpinner();

    this.wsManager.editDevice(
        Number(values.cityId),
        values
    ).subscribe(data => {
      console.log(data);
      this.hideSpinner();
      const confirmMsg = this.translate.instant('Device informations successfully updated');
      this.widgetManager.showToastMessage('success', confirmMsg);
      this.closePopover();
    }, err => {
      this.hideSpinner();
      const errorMsg = this.translate.instant('Error while updating device info');
      this.widgetManager.showToastMessage('danger', errorMsg);
    });
  }
}
