import { Component, OnInit } from '@angular/core';
import {Hub} from '../../classes';
import {NavController, NavParams, Platform, PopoverController} from '@ionic/angular';
import {RentalmanagerService} from '../../services/rentalmanager.service';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-hub-detail',
  templateUrl: './hub-detail.component.html',
  styleUrls: ['./hub-detail.component.scss'],
})
export class HubDetailComponent implements OnInit {

  hub: Hub = null;
  hubImagePath: string = null;
  spinnerVisible  = false;

  private userCoordinates = null;

  constructor(
      private navParams: NavParams,
      private navCtrl: NavController,
      private platform: Platform,
      private rentalManager: RentalmanagerService,
      private translate: TranslateService,
      private popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.hub = new Hub (JSON.parse(this.navParams.get('hub_data')));
    this.hubImagePath = '../../assets/img/hub.png';

    const coords = this.rentalManager.getUserPositionCoordinates();
    // console.log('coords: ', coords);
    this.userCoordinates = {
      userLat: coords.latitude,
      userLng: coords.longitude
    };
  }

  showSpinner() {
    this.spinnerVisible = true;
  }

  hideSpinner() {
    this.spinnerVisible = false;
  }

  /*****************
   OPEN NAVIGATOR
   */
  navigateToHubWithPlugin(hubId) {
    console.log('@@@@@navigateToTheVehicleWithPlugin ', hubId);
  }

  /*****************
  DRAW ROUTE ON MAP
   */
  drawRoute(hubId) {
    this.showSpinner();
    setTimeout( () => {
      this.hideSpinner();
      // Chiudi il popover passando al chiamante i parametri per mostrare la strada
      this.closePopover(true, hubId);
    }, 1000);
  }

  closePopover(showRoute = false, hubId = null) {
    const dataPar = {
      showRoutePar: showRoute,
      deviceIdPar: null,
      hubIdPar: hubId,
      vehicleLatitudePar: this.hub.latitude,
      vehicleLongitude: this.hub.longitude,
      userLatitudePar:  this.userCoordinates.userLat,
      userLongitudePar:  this.userCoordinates.userLng
    };
    this.popoverController.dismiss(dataPar);
  }
}
